import {Const} from "../utils/Utils";
import selectionManager from "./SelectionManager";

class ColorDots {
    constructor() {
        this.groups = {};
        this.createSnapPoints = this.createSnapPoints.bind(this);
    }

    addCloud(cloud) {
        this.groups[cloud.id] = cloud;
        cloud.onPointsUpdated = this.pointsUpdated.bind(this);
        cloud.onSizeChanged = selectionManager.refresh;
        this.pointsUpdated();
    }

    pointsUpdated() {
        let groups = this.getActiveGroups();

        let counts = {
            [Const.COLOR_SPHERE]: 0,
            [Const.COLOR_DATA]: 0,
            [Const.IMAGE_DATA]: 0,
        };
        groups.forEach(group => {
            counts[group.type] += group.count;
        });

        for (let groupKey in counts) {
            let count = counts[groupKey];
            displayCount(groupKey, count > 0, count);
        }
    }

    createSnapPoints() {
        let snapPoints = [];
        let groups = this.getActiveGroups();
        groups.forEach(group => {
            snapPoints = snapPoints.concat(group.createSnapPoints());
        });
        return snapPoints;
    }

    clear(type) {
        for (let key in this.groups) {
            let group = this.groups[key];
            if (group.type === type) {
                group.dispose();
                delete this.groups[key];
            }
        }
    }

    getActiveGroups() {
        return Object.values(this.groups).filter(group => group.enabled);
    }

    getCloud(name) {
        return this.groups[name];
    }
}

function displayCount(mode, show, count) {
    let countLabel;
    switch (mode) {
        case Const.COLOR_SPHERE:
            countLabel = document.getElementById("colorGridInfo");
            if (count != null) {
                countLabel.innerHTML = count + ' <span style="color: #7ad0c1">Color Grid Points</span>';
            }
            break;
        case Const.COLOR_DATA:
            countLabel = document.getElementById("csvInfo");
            if ((count != null)) {
                countLabel.innerHTML = count + ' <span style="color: #7ad0c1">Color Data Points</span>';
            }
            break;
        case Const.IMAGE_DATA:
            countLabel = document.getElementById("imageDataInfo");
            if ((count != null)) {
                countLabel.innerHTML = count + ' <span style="color: #7ad0c1">Image Data Points</span>';
            }
            break;
    }
    if (countLabel) {
        countLabel.style.display = (show && (count != 0)) ? "block" : "none";
    }
}

export const colorDots = new ColorDots();
window.colorDots = colorDots;