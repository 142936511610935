import {Line3, Vector3} from "three";
import {colorDots} from "../core/ColorDots";
import selectionManager from "../core/SelectionManager";
import InfoPopup from "../ui/InfoPopup";
import {camera, raycaster} from "../Scene";
import {Const, Utils} from "../utils/Utils";
import pointConnector from "../core/PointConnector";
import userColors from "../clouds/UserColors";

/*
 This is the input handler for interacting with the color dots.

 When a color dot is clicked it is selected.

 If the color dot is a user defined one, user can move the dot between 2 dot's which were used to create it.
 It's like a slider thumb moving between 2 dots.
 */
export class ColorDotsInputHandler {
    constructor() {
        this.currentDot = null;
        this.setDragging(false);
        this.planeNormal = new Vector3(0, 0, 1);
    }

    tap(e) {
        let castResult = this.getIntersection(e);

        if (castResult.hit) {
            let pickedDot = castResult.getPointCloudDot();
            this.selectColorDot(pickedDot);
            return true;
        }
        return false;
    }

    pointerDown(e) {
        let castResult = this.getIntersection(e);

        if (castResult.hit) {
            let pickedDot = castResult.getPointCloudDot();

            if (pickedDot.type === Const.COLOR_BEING_UPDATED) {
                e.preventDefault();
                this.startSliderDrag(pickedDot);
                this.setDragging(true);
                this.createSnapPoints();

                return true;
            }
        }
        return false;
    }

    pointerMove(e) {
        if (this.isDragging) {
            e.preventDefault();
            this.dragSlider(e);
            return true;
        }
    }

    pointerUp(e) {
        if (this.isDragging) {
            e.preventDefault();
            this.currentDot = null;
            this.setDragging(false);
        }
        return false;
    }

    createSnapPoints() {
        this.snapPoints = [];
        for (let i = 0; i <= 1; i += 0.25) {
            this.snapPoints.push(i);
        }
    }

    snap(point) {
        const shouldSnap = (point, closestSnapPoint) => {
            let threshold = 0.02;
            return Math.abs(closestSnapPoint - point) < threshold;
        };

        const diffToSnapPoint = (point, snapPoint) => {
            return Math.abs(point - snapPoint);
        };

        let closest = this.snapPoints.reduce(function (prev, curr) {
            let diffPrev = diffToSnapPoint(point, curr);
            let diffCurrent = diffToSnapPoint(point, prev);

            return (diffCurrent > diffPrev) ? curr : prev;
        });

        if (shouldSnap(point, closest)) {
            return closest;
        }
        return point;
    }

    selectColorDot(pickedDot) {
        selectionManager.setSelectedDot(pickedDot);
        userColors.clearIncompleteDots();
        InfoPopup.showPointData(pickedDot, 1);

        if (pickedDot.menuActive) {
            InfoPopup.showMenu(pickedDot);
        }
    }

    getIntersection(event) {
        let groups = colorDots.getActiveGroups().map(cloud => cloud.cloud);
        return raycaster.rayCastObjects(event, groups);
    }

    setDragging(dragging) {
        this.isDragging = dragging;
        Utils.setCursor(dragging ? 'ew-resize' : '');
    }

    startSliderDrag(dot) {
        let sliderStartDot = ColorDotsInputHandler.sliderStartDot;
        let sliderEndDot = ColorDotsInputHandler.sliderEndDot;

        this.sliderStartPoint = sliderStartDot.position;
        this.sliderEndPoint = sliderEndDot.position;
        this.sliderLine = new Line3(sliderStartDot.position, sliderEndDot.position);

        // Calculate a plane which is coplanar with the slider line and facing to the camera.
        // We'll use this plane to raycast the mouse to, and calculate the position of the dot on the slider line.

        let sliderDirection = new Vector3();
        this.sliderLine.delta(sliderDirection);

        this.planeNormal = sliderDirection.clone();
        this.planeNormal.cross(camera.getWorldDirection());
        this.planeNormal.cross(sliderDirection.multiplyScalar(-1));

        this.currentDot = dot;
    }

    dragSlider(event) {
        let mousePosition = raycaster.intersectPlane(event, this.planeNormal, this.sliderStartPoint);

        let sliderPosition = this.sliderLine.closestPointToPointParameter(mousePosition, true);

        if (!(event.ctrlKey || event.shiftKey)) {
            sliderPosition = this.snap(sliderPosition);
        }

        let middleDotPosition = new Vector3();
        middleDotPosition.lerpVectors(this.sliderStartPoint, this.sliderEndPoint, sliderPosition);

        this.currentDot.position = middleDotPosition;

        selectionManager.setMiddleDot(this.currentDot);
        pointConnector.lineBetweenPointsMulti(ColorDotsInputHandler.sliderStartDot, this.currentDot, ColorDotsInputHandler.sliderEndDot);

        // InfoPopup.showPointData(this.currentDot, 1);
        InfoPopup.showColorMix(ColorDotsInputHandler.sliderStartDot, ColorDotsInputHandler.sliderEndDot, this.currentDot);
    }
}
