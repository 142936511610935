import {height, width} from '../Scene.js'
import {rgb2lab} from "../functions.js";
import {PointCloud} from "../core/PointCloud";
import {colorDots} from "../core/ColorDots";
import {Const, Debug} from "../utils/Utils";
import {DotControls} from "../ui/ui";
import {lab2xyz, rgb2xyz} from "../functions";

export default class ImageData {
    constructor(args) {
        this.setActive = this.setActive.bind(this);
        this.enabled = true;
        this.active = false;
        this.image = null;

        this.canvas = document.createElement('canvas');
        this.ctx = null;

        this.density = Debug.config[Const.IMAGE_DATA].density || 90;

        this.file = {
            loadFile: function () {
                console.log("image_data: LoadFile");
                document.getElementById('myImgInput').click();
            }
        };

        this.addEvents();
        this.initializeImage();

    }

    initializeImage() {
        let self = this;

        let smaller = (width > height) ? height : width;
        this.w0 = Math.round(smaller * 0.35);
        this.h0 = Math.round(smaller * 0.35);

        this.imgSize = {
            w: this.w0,
            h: this.h0
        };

        let $slides = $("#imageDataCont #slides");
        let $loadedImage = $("#imageDataCont #loadedImage");

        this.initialImage = $("#imageDataCont .imgCont img")[0];

        // On before slide change
        $slides.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            console.log('slick beforeChange')
            let img = $(slick.$slides[nextSlide]).find('img')[0];
            self.showImageAsColorDots(img);
            console.log(event, slick, currentSlide, nextSlide);
        });

        $slides.slick(
            {
                variableWidth: true,
                dots: true,
            });

        $loadedImage.hide();
        $loadedImage.draggable({});
        $loadedImage.resizable({
            handles: 'all',
            stop: (e, ui) => {
                this.imgSize.width = ui.size.width;
                this.imgSize.h = ui.size.height;
            }
        });
    }

    static displayLoadedImage(img) {
        let $loadedImageContainer = $("#imageDataCont #loadedImage");
        let $slides = $("#imageDataCont #slides");

        $loadedImageContainer.css('background-image', 'url(' + img.src + ')');

        let w = $loadedImageContainer.width();
        let h = w * img.height / img.width;

        $loadedImageContainer.css('height', h + 'px');
        $loadedImageContainer.show();

        $slides.hide();
    }

    setActive(active) {
        if (active) {
            $("#imageDataCont").show();
            //Fix a bug on slick that results wrong slide to be displayed initially.
            let $slides = $("#imageDataCont #slides");
            $slides.slick('setPosition');
        } else {
            $("#imageDataCont").hide();
        }
        if (!this.cloud) {
            this.load();
        }
        this.cloud.setEnabled(active);
        DotControls.setControlValuesFromConfig($('#ui_c_image'), Debug.config[Const.IMAGE_DATA]);
    }

    addEvents() {
        let el = document.getElementById("myImgInput");

        el.onchange = (evt) => {
            this.load(evt)
        }
    }

    showImageAsColorDots(img) {
        if (img) this.image = img;

        this.prepData();

        // this.animData();
        if (!this.created) {
            this.createParticles();
        } else {
            this.updateParticles();
        }
    }

    loadImage(src) {
        let self = this;
        let img = new Image();
        img.onload = () => {
            self.showImageAsColorDots(img);
            ImageData.displayLoadedImage(img);
        };
        img.src = src;
    }

    load(evt) {
        let _this = this;
        let tgt, files;
        if (evt) {
            tgt = evt.target || window.event.srcElement;
            files = tgt.files;
        }

        if (FileReader && files && files.length) {
            let fr = new FileReader();
            fr.onload = function () {
                _this.loadImage(fr.result)
            };
            fr.readAsDataURL(files[0]);
        } else {
            _this.showImageAsColorDots(_this.initialImage);
        }
    }

    updateVal(mode, val) {
        switch (mode) {
            case 'density':
                this.density = val;
                this.prepData();
                this.updateParticles();
                break;
        }
    }

    prepData() {
        // fix w h to wrap def
        let scale = this.density / 100;
        if (scale === 0) {
            scale = 1 / this.w0
        }

        this.w = Math.round(this.w0 * scale * 1.75);
        this.h = Math.round(this.h0 * scale * 1.75);

        this.ir = this.image.width / this.image.height;
        this.imgSize.h = this.imgSize.w / this.ir;
        console.log(this.imgSize.w, this.ir);
        console.log(this.imgSize.w / this.ir);


        var w0 = this.w0;
        var h0 = this.h0;
        if (this.ir > 1) {
            this.h = Math.round(this.w / this.ir);
            h0 = Math.round(w0 / this.ir);
        } else {
            this.w = Math.round(this.h * this.ir);
            w0 = Math.round(h0 * this.ir);
        }

        if ((this.w <= 0) || isNaN(this.w)) {
            this.w = 1
        }
        if ((this.h <= 0) || isNaN(this.h)) {
            this.h = 1
        }

        console.log("prepData:", this.w, this.h, this.w0, this.h0, this.ir, this.imgSize)

        this.canvas.width = this.w;
        this.canvas.height = this.h;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.drawImage(this.image, 0, 0, this.w, this.h);
        this.allData = this.getPixelColor(this.ctx, 0, 0, this.w, this.h);
    }

    getPixelColor(ctx, x, y, w, h) {
        return ctx.getImageData(x, y, w, h).data;
    }

    getPoints() {
        let particleCount;
        particleCount = this.w * this.h;

        let translateArray = new Array(particleCount * 3);

        for (let i = 0; i < particleCount; i++) {
            let r = this.allData[i * 4 + 0];
            let g = this.allData[i * 4 + 1];
            let b = this.allData[i * 4 + 2];

            let {x, y, z} = rgb2xyz(r, g, b, Const.RADIUS);

            translateArray[i * 3 + 0] = x;
            translateArray[i * 3 + 1] = y;
            translateArray[i * 3 + 2] = z;
        }
        return translateArray;
    }

    createParticles() {
        let points = this.getPoints();
        this.cloud = new PointCloud(points, Const.IMAGE_DATA, Debug.config[Const.IMAGE_DATA]);
        colorDots.addCloud(this.cloud);
        this.created = true;
    }

    updateParticles() {
        let points = this.getPoints();
        this.cloud.update(points);
    }
}
