import {lab2rgb, lab2xyz, rgb2lab, xyz2lab} from "../functions";
import {Const} from "../utils/Utils";

export default class LabColor {
    constructor(l, a, b) {
        this._l = l;
        this._a = a;
        this._b = b;
        this.defineProperties();
    }

    static fromXYZ(x, y, z) {
        let lab = xyz2lab(x, y, z, Const.RADIUS);
        let color = new LabColor(lab.l, lab.a, lab.b);
        color._x = x;
        color._y = y;
        color._z = z;
        return color;
    }

    static fromRgb(r, g, b) {
        let lab = rgb2lab(r, g, b);
        let color = new LabColor(lab.l, lab.a, lab.b);
        color._rgb_r = r;
        color._rgb_g = g;
        color._rgb_b = b;
        return color;
    }

    createXYZ() {
        if (!this._x) {
            let xyz = lab2xyz(this._l, this._a, this._b);
            this._x = xyz.x;
            this._y = xyz.y;
            this._z = xyz.z;
        }
    }

    createRGB() {
        if (!this._rgb_r) {
            let rgb = lab2rgb(this._l, this._a, this._b);
            this._rgb_r = rgb.r;
            this._rgb_g = rgb.g;
            this._rgb_b = rgb.b;
        }
    }

    defineProperties() {
        Object.defineProperties(this, {
            l: {
                get: () => {
                    return this._l.toFixed(2);
                }
            },
            a: {
                get: () => {
                    return this._a.toFixed(2);
                }
            },
            b: {
                get: () => {
                    return this._b.toFixed(2);
                }
            },
            x: {
                get: () => {
                    this.createXYZ();
                    return this._x;
                }
            },
            y: {
                get: () => {
                    this.createXYZ();
                    return this._y;
                }
            },
            z: {
                get: () => {
                    this.createXYZ();
                    return this._z;
                }
            },
            rgb_r: {
                get: () => {
                    this.createRGB();
                    return this._rgb_r;
                }
            },
            rgb_g: {
                get: () => {
                    this.createRGB();
                    return this._rgb_g;
                }
            },
            rgb_b: {
                get: () => {
                    this.createRGB();
                    return this._rgb_b;
                }
            },
            lab: {
                get: () => {
                    return {l: this.l, a: this.a, b: this.b}
                }
            },
            rgb: {
                get: () => {
                    return {r: this.rgb_r, g: this.rgb_g, b: this.rgb_b}
                }
            },
            xyz: {
                get: () => {
                    return {x: this.x, y: this.y, z: this.z}
                }
            },
        })
    }
}


