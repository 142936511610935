class Toast {
    constructor(id, timeout) {
        this.timeout = timeout;
        this.$toast = $('#' + id);
        this.$toastText = this.$toast.find('#toast-text');
        this.$closeButton = this.$toast.find('button');
        this.$closeButton.on('click',this.hide.bind(this));
    }

    showMessage(message,timeout = this.timeout) {
        let self = this;
        this.$toastText.html(message);
        this.$toast.show();

        this.timer = setTimeout(() => {
            self.hide();
        }, this.timeout);
    }

    hide() {
        this.$toast.hide();
        clearTimeout(this.timer);
    }
}

export const toast = new Toast('toast',3000);