import {Euler, Math, PerspectiveCamera, Quaternion, Vector3} from "three";

class CamControl {
    constructor(options,domElement) {
        this.domElement = domElement;
        this.options = {
            fov: 35,
            distance: 90,
            distRange: {
                max: Number.POSITIVE_INFINITY,
                min: Number.NEGATIVE_INFINITY
            },
            focusPos: new Vector3(),
            rotation: new Vector3(),
            rotRange: {
                xMax: Number.POSITIVE_INFINITY,
                xMin: Number.NEGATIVE_INFINITY,
                yMax: 90,
                yMin: -90
            },
            eyeSeparation: 0
        };
        this.mergeOptions(options);

        this.distActual = this.options.distance;
        this.distTarget = this.options.distance;
        this.focusActual = this.options.focusPos.clone();
        this.focusTarget = this.options.focusPos.clone();
        this.rotActual = this.options.rotation.clone();
        this.rotTarget = this.options.rotation.clone();
        var vpW = window.innerWidth;
        var vpH = window.innerHeight;

        this.camera = new PerspectiveCamera(this.options.fov, vpW / vpH, 0.1, 100);

        this.radians = Math.PI / 180;
        this.quatX = new Quaternion();
        this.quatY = new Quaternion();
        this.gyro = {orient: 0};
        if (typeof window.orientation !== 'undefined') {
            this.defaultEuler = new Euler(90 * this.radians, 180 * this.radians, (180 + parseInt(window.orientation.toString(), 10)) * this.radians);
        } else {
            this.defaultEuler = new Euler(0, 0, 0);
        }
    }

    mergeOptions(options) {
        for (let key in options) {
            if (key === 'rotRange') {
                for (let key in options.rotRange) {
                    this.options.rotRange[key] = options.rotRange[key];
                }
            } else if (key === 'distRange') {
                for (let key in options.distRange) {
                    this.options.distRange[key] = options.distRange[key];
                }
            } else {
                this.options[key] = options[key];
            }
        }
    }

    reset() {
        this.orbitTo(this.options.rotation.x, this.options.rotation.y);
        // this.rotTarget.copy(this.options.rotation);
        this.distTarget = this.options.distance;
        this.focusTarget.copy(this.options.focusPos);
    }

    setDistRange(range) {
        this.mergeOptions({distRange: range});
    }

    setRotRange(range) {
        this.mergeOptions({rotRange: range});
    }

    setDistance(dist) {
        if (dist === void 0) {
            dist = 150;
        }
        this.distActual = dist;
        this.distTarget = dist;
    };

    setAngleRange(xMax, xMin, yMax, yMin) {
        if (xMax === void 0) {
            xMax = Number.POSITIVE_INFINITY;
        }
        if (xMin === void 0) {
            xMin = Number.NEGATIVE_INFINITY;
        }
        if (yMax === void 0) {
            yMax = 90;
        }
        if (yMin === void 0) {
            yMin = -90;
        }
        this.options.rotRange.xMax = xMax;
        this.options.rotRange.xMin = xMin;
        this.options.rotRange.yMax = yMax;
        this.options.rotRange.yMin = yMin;
    };

    setRotation(_rotX, _rotY, _rotZ) {
        if (_rotX === void 0) {
            _rotX = 0;
        }
        if (_rotY === void 0) {
            _rotY = 0;
        }
        if (_rotZ === void 0) {
            _rotZ = 0;
        }
        this.rotActual.set(_rotX, _rotY, _rotZ);
        this.rotTarget.set(_rotX, _rotY, _rotZ);
        this.gyro.alpha = undefined;
        this.gyro.beta = undefined;
        this.gyro.gamma = undefined;
    };

    setFocusPos(_posX, _posY, _posZ) {
        if (_posX === void 0) {
            _posX = 0;
        }
        if (_posY === void 0) {
            _posY = 0;
        }
        if (_posZ === void 0) {
            _posZ = 0;
        }
        this.focusActual.set(_posX, _posY, _posZ);
        // this.focusTarget.set(_posX, _posY, _posZ);
    };

    dolly(distance) {
        this.distTarget += distance / 100;
        this.distTarget = Math.clamp(this.distTarget, this.options.distRange.min, this.options.distRange.max);
    };

    orbitBy(angleX, angleY) {
        this._setRotationClamped(this.rotTarget.x + angleX, this.rotTarget.y + angleY);
    };

    orbitTo(angleX, angleY) {
        angleX = this._getNormalizedTargetAngle(this.rotActual.x, angleX);
        // angleY = this._getNormalizedTargetAngle(this.rotActual.y, angleY);
        this._setRotationClamped(angleX, angleY);
    };

    _getNormalizedTargetAngle(fromAngle, toAngle) {
        let currentAngle = fromAngle % 360;
        let targetAngle = toAngle % 360;
        let diffAngle = targetAngle - currentAngle;

        if (diffAngle > 180) {
            diffAngle -= 360;
        }
        if (diffAngle < -180) {
            diffAngle += 360;
        }
        return diffAngle + fromAngle;
    }

    _setRotationClamped(x, y) {
        x = Math.clamp(x, this.options.rotRange.xMin, this.options.rotRange.xMax);
        y = Math.clamp(y, this.options.rotRange.yMin, this.options.rotRange.yMax);

        this.rotTarget.x = x;
        this.rotTarget.y = y;
    }


    panLeft(distance, objectMatrix) {
        var v = new Vector3();

        v.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
        v.multiplyScalar(-distance);

        this.focusTarget.add(v);

    }

    panUp(distance, objectMatrix) {
        let v = new Vector3();
        v.setFromMatrixColumn(objectMatrix, 1);
        v.multiplyScalar(distance);

        this.focusTarget.add(v);
    };

    // pan(event) {
    pan(deltaX, deltaY) {
        let offset = new Vector3();
        let element = this.domElement;

        // perspective
        let position = this.camera.position;
        offset.copy(position).sub(this.focusTarget);
        let targetDistance = offset.length();

        // half of the fov is center to top of screen
        targetDistance *= window.Math.tan((this.camera.fov / 2) * window.Math.PI / 180.0);

        // we use only clientHeight here so aspect ratio does not distort speed
        this.panLeft(2 * deltaX * targetDistance / element.clientHeight, this.camera.matrix);
        this.panUp(2 * deltaY * targetDistance / element.clientHeight, this.camera.matrix);

    };

    onWindowResize(vpW, vpH) {
        this.camera.aspect = vpW / vpH;
        this.camera.updateProjectionMatrix();
    };

    onDeviceReorientation(orientation) {
        this.gyro.orient = orientation * this.radians;
    };

    onGyroMove(alpha, beta, gamma) {
        var acc = this.gyro;
        acc.alpha = alpha;
        acc.beta = beta;
        acc.gamma = gamma;
    };

    update() {
        let damp = this.options.damping;
        this.distTarget = Math.clamp(this.distTarget, this.options.distRange.min, this.options.distRange.max);
        this.distActual += (this.distTarget - this.distActual) * damp;
        this.focusActual.lerp(this.focusTarget, damp);
        this.camera.position.copy(this.focusActual);
        if (this.gyro.alpha && this.gyro.beta && this.gyro.gamma) {
            this.camera.setRotationFromEuler(this.defaultEuler);
            this.camera.rotateZ(this.gyro.alpha * this.radians);
            this.camera.rotateX(this.gyro.beta * this.radians);
            this.camera.rotateY(this.gyro.gamma * this.radians);
            this.camera.rotation.z += this.gyro.orient;
        } else {
            this.rotActual.lerp(this.rotTarget, damp);
            this.quatX.setFromAxisAngle(CamControl.axisX, -Math.degToRad(this.rotActual.y));
            this.quatY.setFromAxisAngle(CamControl.axisY, -Math.degToRad(this.rotActual.x));
            this.quatY.multiply(this.quatX);
            this.camera.quaternion.copy(this.quatY);
        }
        this.camera.translateZ(this.distActual);
    };

    follow(target) {
        this.distTarget = Math.clamp(this.distTarget, this.options.distRange.min, this.options.distRange.max);
        this.distActual += (this.distTarget - this.distActual) * 0.01;
        // this.focusTarget.set(target.x, target.y + 1, target.z + this.distActual);
        this.focusTarget.set(target.x, target.y, target.z);
        this.focusActual.lerp(this.focusTarget, 0.01);
        this.camera.position.copy(this.focusActual);
        this.camera.lookAt(target);
    };
}

CamControl.axisX = new Vector3(1, 0, 0);
CamControl.axisY = new Vector3(0, 1, 0);

export default CamControl;
