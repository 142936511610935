import {getPointOnScreen} from "../Scene";
import {Vector3} from "three";
import {Const} from "../utils/Utils";

export default class PointCloudDot {
    constructor(index, cloud) {
        this.cloud = cloud;
        this.id = index;
        this._position = new Vector3(
            cloud.positions.getX(index),
            cloud.positions.getY(index),
            cloud.positions.getZ(index),
        );
        this.createSnapPoints = cloud.createSnapPoints;
        this.defineProperties();
    }

    defineProperties() {
        Object.defineProperties(this, {
            scale: {
                get: () => this.cloud.size / 100,
            },
            size: {
                get: () => this.cloud.size * 1.0,
            },
            type: {
                get: () => this.cloud.type,
            },
            isColorData: {
                get: () => {
                    return this.type === Const.COLOR_DATA ||
                        this.type === Const.USER_COLORS ||
                        this.type === Const.COLOR_BEING_UPDATED
                }
            },
            groupName: {
                get: () => this.cloud.id,
            },
            color: {
                get: () => this.cloud.getColor(this.id),
            },
            position: {
                get: () => this._position,
                set: (position) => {
                    this._position = position;
                    this.cloud.updatePoint(position, this.id);
                }
            },
            menuActive: {
                get: () => this.cloud.menuActive
            },
            positionOnScreen: {
                get: () => getPointOnScreen(this.cloud.cloud, this._position)
            }
        })
    }
}
