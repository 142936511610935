import {Sprite, SpriteMaterial, TextureLoader} from "three";
import {Debug} from "./utils/Utils";

const assetsToLoad = [
    {name: 'glow_particle', url: "./assets/images/glow_particle.png", type: 'texture'},
    {name: 'particle8', url: "./assets/images/particle8.png", type: 'texture'},
];

const createIndicator = (material) => {
    let sprite = new Sprite(material);
    sprite.scale.set(0.5, 0.5, 0.5);
    return sprite;
};

export let selectionIndicator;
export let connectionIndicator;
export let middleDotIndicator;

export default class AssetLoader {
    constructor() {
        // this.assets = {};
        this.textureLoader = new TextureLoader();
        this.assetIndex = -1;
        this.listeners = [];
        this.loadNext();
        this.finished = false;
    }

    loadNext() {
        this.assetIndex++;
        if (this.assetIndex < assetsToLoad.length) {
            let asset = assetsToLoad[this.assetIndex];
            this.loadAsset(asset);
        } else {
            this.createObjects();
            this.finished = true;
            this.listeners.forEach(listener => {
                listener.call(this);
            });
        }
    }

    loadAsset(asset) {
        let url = Debug.getPath(asset.url);

        console.log('loadAsset', asset);
        let self = this;
        switch (asset.type) {
            case 'texture':
                this.textureLoader.load(url, function (texture) {
                    self.storeAsset(asset.name, texture)
                });
        }
    }

    storeAsset(name, asset) {
        this[name] = asset;
        this.loadNext();
    }

    addListener(listener) {
        if (!this.listeners.includes(listener)) {
            this.listeners.push(listener);
        }
    }

    createObjects() {
        let texture = assets['glow_particle'];
        let selectionMaterial = new SpriteMaterial({
            map: texture,
            color: 0x666666,
            depthTest: false,
            transparent: true,
            opacity: 0,
        });

        let connectionMaterial = selectionMaterial.clone();
        connectionMaterial.color.set(0x000000);

        let middleDotMaterial = selectionMaterial.clone();
        middleDotMaterial.color.set(0x8ACBAB);

        selectionIndicator = createIndicator(selectionMaterial);
        connectionIndicator = createIndicator(connectionMaterial);
        middleDotIndicator = createIndicator(middleDotMaterial);

    }
}

export const assets = new AssetLoader();
// window.assetLoader = assets;