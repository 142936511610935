import {toast} from "./Toast";
import {Debug} from "../utils/Utils";
import Auth from 'j-toker';

class LoginForm {
    constructor(formId) {
        this.url = 'https://www.color-finder.net/customers/private';
        this.$modal = $('#main-modal');
        this.$errorMessage = $('#login-error-message');

        let $form = $(`#${formId}`);
        this.$submitBtn = $form.find('button');
        this.$submitBtn.on('click', this.formSubmitted.bind(this));

        this.$password = $form.find('input[type="password"]');
        this.$username = $form.find('input[type="text"]');

        this.loggedIn = !Debug.isPublicApp();

        if (!this.loggedIn) {
            let self = this;
            Auth.configure({apiUrl: '/api'})
                .then(user => self.onLoginSuccess(user, 'configure'))
                .fail(resp => self.loggedIn = false);
        }
    }

    show() {
        this.$modal.addClass('active');
    }

    hide() {
        this.$modal.removeClass('active');
    }

    logOut() {
        Auth.signOut();
        this.loggedIn = false;
    }

    onLoginFailed(data) {
        this.loggedIn = false;
        this.$errorMessage.show();
    }

    onLoginSuccess(user, msg = 'somewhere else') {
        this.hide();
        this.loggedIn = true;
        this.$errorMessage.hide();

        toast.showMessage(`Welcome ${user.name}!`);
    }

    formSubmitted(event) {

        event.preventDefault();
        event.stopPropagation();
        let userName = this.$username.val();
        let password = this.$password.val();

        // window.location.href = './private';
        let self = this;
        Auth.emailSignIn({
            email: userName,
            password: password
        }).then(user => self.onLoginSuccess(user.data))
            .fail(resp => self.onLoginFailed(resp));
    }
}

export const login = new LoginForm('login-form');