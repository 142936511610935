import {BufferGeometry} from "three";
import {Const} from "./utils/Utils";

export function getRad(deg) {
    return deg * Math.PI / 180;
}

export function lab2xyz(l, a, b, radius) {
    let x = (a / 127) * radius;
    let y = (l / 50 - 1) * radius;
    let z = b / 127 * -radius;
    return {x, y, z};
}

export function xyz2lab(x, y, z, radius) {
    let a = 127 * x / radius;
    let b = 127 * z / -radius;
    let l = 50 * (y + radius) / radius;

    return {l, a, b};
}

export function lab2rgb(l, a, b) {
    let y = (l + 16) / 116,
        x = a / 500 + y,
        z = y - b / 200,
        r, g, lb;

    x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16 / 116) / 7.787);
    y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16 / 116) / 7.787);
    z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16 / 116) / 7.787);

    r = x * 3.2406 + y * -1.5372 + z * -0.4986;
    g = x * -0.9689 + y * 1.8758 + z * 0.0415;
    lb = x * 0.0557 + y * -0.2040 + z * 1.0570;

    r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1 / 2.4) - 0.055) : 12.92 * r;
    g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1 / 2.4) - 0.055) : 12.92 * g;
    lb = (lb > 0.0031308) ? (1.055 * Math.pow(lb, 1 / 2.4) - 0.055) : 12.92 * lb;

    r = Math.round(Math.max(0, Math.min(1, r)) * 255);
    g = Math.round(Math.max(0, Math.min(1, g)) * 255);
    lb = Math.round(Math.max(0, Math.min(1, lb)) * 255);
    return {r, g, b: lb};
}

export function rgb2lab(r, g, b) {
    r = r / 255;
    g = g / 255;
    b = b / 255;
    let x, y, z;

    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

    x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
    y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
    z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;

    let l = (116 * y) - 16;
    let a = 500 * (x - y);
    let lb = 200 * (y - z);
    return {l, a, b: lb};
}

export function rgb2xyz(r, g, b, radius) {
    let lab = rgb2lab(r, g, b);
    return lab2xyz(lab.l, lab.a, lab.b, radius)
}


export function xyz2rgb(x, y, z) {
    let lab = xyz2lab(x, y, z, Const.RADIUS);
    return lab2rgb(lab.l, lab.a, lab.b);
}

// calculate the perceptual distance between colors in CIELAB
// https://github.com/THEjoezack/ColorMine/blob/master/ColorMine/ColorSpaces/Comparisons/Cie94Comparison.cs

function formatNum(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export function deltaE(labA, labB) {
    let deltaL = Math.abs(labA.l - labB.l);
    let deltaA = Math.abs(labA.a - labB.a);
    let deltaB = Math.abs(labA.b - labB.b);
    let deltaE = Math.sqrt(deltaL * deltaL + deltaA * deltaA + deltaB * deltaB);
    return {
        deltaL: formatNum(deltaL),
        deltaA: formatNum(deltaA),
        deltaB: formatNum(deltaB),
        deltaE: formatNum(deltaE)
    };
}


export function dist3d(p1, p2) {
    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    var dz = p1.z - p2.z;

    return Math.sqrt(dx * dx + dy * dy + dz * dz);
}


export function findPointOnCircle(originX, originY, radius, angleRadians) {
    var newX = radius * Math.cos(angleRadians) + originX
    var newY = radius * Math.sin(angleRadians) + originY

    return {"x": newX, "y": newY}
}


function getRandomSpherePoint(radius) {
    var u = Math.random();
    var v = Math.random();
    var theta = u * 2.0 * Math.PI;
    var phi = Math.acos(2.0 * v - 1.0);
    var r = Math.cbrt(Math.random()) * radius;
    var sinTheta = Math.sin(theta);
    var cosTheta = Math.cos(theta);
    var sinPhi = Math.sin(phi);
    var cosPhi = Math.cos(phi);
    var x = r * sinPhi * cosTheta;
    var y = r * sinPhi * sinTheta;
    var z = r * cosPhi;
    return {x: x, y: y, z: z};
}


BufferGeometry.prototype.merge = function (geometry) {

    if (geometry instanceof BufferGeometry === false) {

        console.error('BufferGeometry.merge(): geometry not an instance of BufferGeometry.', geometry);
        return;

    }

    var attributes = this.attributes;

    if (this.index) {

        var indices = geometry.index.array;

        var offset = attributes['position'].count;

        for (var i = 0, il = indices.length; i < il; i++) {

            indices[i] = offset + indices[i];

        }

        this.index.array = Uint32ArrayConcat(this.index.array, indices);

    }

    for (var key in attributes) {

        if (geometry.attributes[key] === undefined) continue;

        attributes[key].array = Float32ArrayConcat(attributes[key].array, geometry.attributes[key].array);

    }

    return this;

    /***
     * @param {Float32Array} first
     * @param {Float32Array} second
     * @returns {Float32Array}
     * @constructor
     */
    function Float32ArrayConcat(first, second) {
        var firstLength = first.length,
            result = new Float32Array(firstLength + second.length);

        result.set(first);
        result.set(second, firstLength);

        return result;
    }

    /**
     * @param {Uint32Array} first
     * @param {Uint32Array} second
     * @returns {Uint32Array}
     * @constructor
     */
    function Uint32ArrayConcat(first, second) {
        var firstLength = first.length,
            result = new Uint32Array(firstLength + second.length);

        result.set(first);
        result.set(second, firstLength);

        return result;
    }

};