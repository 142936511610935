import {Debug} from "../utils/Utils";
import {colorDots} from "../core/ColorDots";
import {gsap} from "gsap";

const INTRO_MODES = {IMAGE: 0, VIDEO: 1};

export default class Intro {
    constructor(args) {
        this.enabled = !Debug.debug;
        let videoConfig = Debug.config.intro.video;
        let imageConfig = Debug.config.intro.image;

        let imageContainer = $('#imageIntro');
        let videoContainer = $('#videoIntro');
        console.log('INTRO create');

        if (videoConfig) {
            console.log('Loading video intro');

            this.mode = INTRO_MODES.VIDEO;
            imageContainer.hide();
            this.video = $("#intro_video");
            let videoSources = `<source src="${videoConfig.webm}" type='video/webm;codecs="vp8, vorbis"'/>
                                <source src="${videoConfig.mp4}" type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>`;
            this.video.html(videoSources);
        } else if (imageConfig) {
            console.log('Loading image intro');

            this.mode = INTRO_MODES.IMAGE;
            videoContainer.hide();
            let image = `<img src="${imageConfig}"/>`;
            imageContainer.html(image);
            this.image = imageContainer;
        }
    }

    init() {
        console.log('INTRO init');
        if (this.enabled) {
            switch (this.mode) {
                case INTRO_MODES.VIDEO:
                    this.initVideoIntro();
                    break;
                case INTRO_MODES.IMAGE:
                    this.initImageIntro();
                    break;
            }
        } else {
            $("#intro").css("display", "none");
        }
    }

    finishingAnimation() {
        this.endIntro();

        let from = {
            x: 3, y: 3, z: 3,
        };
        let to = {
            x: 1, y: 1, z: 1,
            duration: 1.5,
            ease: "power4.out",
            overwrite: true,
        };
        let clouds = colorDots.getActiveGroups().map(group => group.cloud.scale);
        gsap.fromTo(clouds, from, to);
    }

    endIntro() {
        console.log('INTRO end intro');
        $("#intro").delay(0).fadeTo(400, 0, function () {
            $("#intro").css({display: "none"});
        });
    }

    initImageIntro() {
        console.log('INTRO initImageIntro ');
        setTimeout(() => {
            this.finishingAnimation();
        }, 4000);
    }

    initVideoIntro() {
        this.video[0].onended = () => {
            this.finishingAnimation();
        };
    }
}