import Papa from 'papaparse';
import {lab2xyz, xyz2lab} from "../functions";
import fileDownload from 'js-file-download';
import LabColor from "./LabColor";
import UI from "../ui/ui";

const GROUP_KEY = 'group_name';

const convertToNum = (textVal) => {

    return parseFloat(textVal.toString().replace(/,/g, '.'));//.toFixed(2);
};

export default class CsvData {
    constructor(url) {
        this.headers = [];
        this.rows = [];
        this.groupNames = [];
        this.groups = {};
        this.loaded = false;
        this.fileName = 'color-data.csv';
    }

    extractHeaders(row) {
        for (let key in row) {
            if (key) {
                this.headers.push(key);
            }
        }
    }

    load(url, fileName, onLoad) {
        let self = this;
        console.log("loadCSV");
        this.fileName = fileName;

        Papa.parse(url, {
            download: true,
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,

            complete: (results) => {
                self.rows = results.data;
                self.extractHeaders(self.rows[0]);

                self.groupNames = [];
                self.groups = {};
                self.rows.forEach(row => {
                    row.l = convertToNum(row.L_value);
                    row.a = convertToNum(row.a_value);
                    row.b = convertToNum(row.b_value);

                    let xyz = lab2xyz(row.l, row.a, row.b, 1);
                    row.x = xyz.x;
                    row.y = xyz.y;
                    row.z = xyz.z;

                    let groupName = row[GROUP_KEY] || 'Default group';
                    row[GROUP_KEY] = groupName;

                    let group = self._getGroup(groupName);
                    group.push(row);
                });
                this.loaded = true;
                onLoad(this);
            }
        });
    }

    _getGroup(groupName) {
        if (groupName && !this.groupNames.includes(groupName)) {
            this.groupNames.push(groupName);
            this.groups[groupName] = [];
        }
        return this.groups[groupName];
    }

    getRow(groupName, id) {
        return this.groups[groupName][id];
    }

    addRow(dot, groupName) {
        let group = this._getGroup(dot.type);
        let color = LabColor.fromXYZ(dot.position.x, dot.position.y, dot.position.z);
        let row = {
            x: color.x,
            y: color.y,
            z: color.z,

            L_value: color.l,
            a_value: color.a,
            b_value: color.b,

            l: color._l,
            a: color._a,
            b: color._b,

            [GROUP_KEY]: groupName,
            product_type: 'none',
            color_name: 'none',
        };

        if (group.length <= 0) {
            UI.addCsvGroup({
                mode: "add",
                name: groupName,
                id: dot.type,
                enabled: 1,
                opacity: 100,
                size: dot.size
            });
        }

        group.push(row);
        this.rows.push(row);

    }

    download() {
        let csv = Papa.unparse(this.rows,
            {
                header: true,
                skipEmptyLines: true,
                columns: ["group_number", "group_name", "product_type", "color_name", "L_value", "a_value", "b_value"]
            }
        );
        let fileName = this.fileName.split('.')[0];
        fileDownload(csv, `${fileName}-${new Date().toISOString()}.csv`);
    }
}

export const csvData = new CsvData();
window.csvData = csvData;
