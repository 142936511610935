class InputHandler {

    constructor() {
        this.listeners = {};
        this.handlers = new Set();
        let $body = $('body');
        let body = $body[0];

        $body.on('pointerdown', this.pointerDown.bind(this));
        $body.on('pointermove', this.pointerMove.bind(this));
        $body.on('pointerup', this.pointerUp.bind(this));
        body.addEventListener('wheel', this.gestureWheel.bind(this));

        this.isDown = false;
        this.hasMoved = false;
    }

    gestureWheel(event) {
        this.delegateToHandlers(event, 'gestureWheel');
    };

    pointerDown(event) {
        this.delegateToHandlers(event, 'pointerDown');

        this.isDown = true;
        this.dispatch('down', event);
    }

    pointerUp(event) {
        this.delegateToHandlers(event, 'pointerUp');

        if (this.hasMoved) {
            this.dispatch('drop', event);
            this.delegateToHandlers(event, 'drop');
        } else {
            this.dispatch('tap', event);
            this.delegateToHandlers(event, 'tap');
        }
        this.isDown = false;
        this.hasMoved = false;
    }

    pointerMove(event) {
        this.delegateToHandlers(event, 'pointerMove');
        if (this.isDown) {
            this.hasMoved = true;

            this.dispatch('drag', event);
        }
    }

// Subscribers of mouse events
    dispatch(type, event) {
        // console.log('dispatch ',type, event);

        if (this.listeners[type]) {
            this.listeners[type].forEach(listener => {
                event.type = type;
                listener(event);
            })
        }
    }

    addListener(type, listener) {
        if (!this.listeners[type]) {
            this.listeners[type] = new Set();
        }
        this.listeners[type].add(listener);
    }

    addHandler(handler) {
        this.handlers.add(handler);
    }

    delegateToHandlers(event, type) {
        for (let handler of this.handlers) {
            let consumed = false;
            if (handler[type]) {
                consumed = handler[type](event);
            }

            if (consumed) return;
        }
    }
}

export const inputHandler = new InputHandler();