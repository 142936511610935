import {getRad} from "../functions";
import {Vector3} from "three";

export class Utils {

    static setCursor(cursor = '') {
        document.body.style.cursor = cursor;
    }

    static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

// From stackoverflow : https://stackoverflow.com/a/4819886
    static isTouchDevice() {
        let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        let mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    static getRandomDotPositions(count, scale = 1) {
        let translateArray;
        translateArray = new Array(count * 3);

        let index = 0;
        for (let i = 0, l = count; i < l; i++) {
            let v = new Vector3((Math.random() * 2 - 1), (Math.random() * 2 - 1), (Math.random() * 2 - 1));
            if (v.lengthSq() <= 1) {
                v.multiplyScalar(scale).toArray(translateArray, index * 3);
                index++;
            }
        }
        //Return nonempty values
        return translateArray.filter(val => val);
    }


    static getSphericalDotPositions(density, scale = 1) {
        let rSteps = Math.round(density * 84 / 100); // ring steps
        let tRings = Math.round(density * 30 / 100); // total rings
        let rDeep = Math.round(density * 60 / 100); // ring deep

        let translateArray = [];

        let count = 0;
        for (let k = 1; k <= tRings; k++) {
            let r = k / tRings;
            for (let i = 0; i < rDeep; i++) {
                let y = (-1 + i / rDeep * 2);
                for (let j = 0; j < rSteps; j++) {
                    let angle = getRad(360 * j / rSteps);
                    let x = r * Math.cos(angle);
                    let z = r * Math.sin(angle);
                    if (x * x + y * y + z * z <= 1) {
                        translateArray.push(x * scale);
                        translateArray.push(y * scale);
                        translateArray.push(z * scale);
                        count++;
                    }
                }
            }
        }

        // add center points
        for (let i = 0; i < (rDeep + 1); i++) {
            let y = (-1 + i / (rDeep) * 2);
            translateArray.push(0);
            translateArray.push(y * scale);
            translateArray.push(0);
            count++;
        }

        return translateArray;
    }
}

export class Debug {
    static getCustomerName() {
        let path = window.location.pathname;
        path = path.replace('/versions/dev', '');
        path = path.split('/').join('');

        let customerName = path;
        if (customerName === '') {
            customerName = 'public';
        }
        return customerName;
    }

    static isPublicApp() {
        return this.getCustomerName() === 'public';
    }

    static getPath(path) {
        if (Debug.getCustomerName() !== 'public') {
            path = '.' + path;
        }
        return path;
    }
}

window.debug = Debug;
Debug.debug = false;

export const Const = {
    COLOR_SPHERE: 'color_sphere',
    COLOR_DATA: 'color_data',
    IMAGE_DATA: 'image_data',
    COORDINATES: 'coordinates',
    USER_COLORS: 'user-colors',
    COLOR_BEING_UPDATED: 'colors-being-updated',
    RADIUS: 10,
};

Debug.config = null;
window.Debug = Debug;
export const Customer = {
    colorDataPath: Debug.getCustomerName() + '/color_dataset.csv',
    configPath: Debug.getCustomerName() + '/app_config.json'
};
