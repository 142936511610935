import LabColor from "../core/LabColor";
import {height} from "../Scene";

export default class PopupBox {
    constructor(selector, props) {
        this.props = props;

        this.prevBox = props.prevBox;
        this.element = document.getElementById(selector);
        this.$element = $(this.element);

        this.dragged = false;
        let self = this;
        this.$element.draggable({
            drag: function () {
                self.dragged = true;
            }
        });

        let closeButton = PopupBox.getCloseButton();
        this.element.appendChild(closeButton);
        $(closeButton).on('click', this.close.bind(this, false));

        this.content = $(PopupBox.getContentWrapper());
        this.$element.append(this.content);

        if (this.props.onApprove) {
            $(".infoPopup").on("click", ".action-button .i_check", this.props.onApprove);
        }
        if (this.props.onReject) {
            $(".infoPopup").on("click", ".action-button .i_close", this.props.onReject);
        }
    }

    open(content, showButtons = false) {
        if (this.prevBox && !this.dragged) {
            let box = this.prevBox.$element;
            let top = box.outerHeight() + box.position().top + 15;
            let left = box.position().left;
            this.element.style.top = top + 'px';
            this.element.style.left = left + 'px';
        }

        let html = content;

        if (this.color) {
            html = PopupBox.getColorPreview(this.color) + html;
        }
        if (showButtons) {
            html += PopupBox.getActionButtons();
        }


        this.content.html(html);
        this.$element.show();
    }

    openWithCsvData(data, showButtons) {
        this.color = new LabColor(data.l, data.a, data.b);
        this.data = data;
        let html = `
                <span class='info_label small-text'>Group Name </span><br> <span>${data.group_name}</span> <br>
                <span class='info_label small-text'>Product Type </span><br> <span>${data.product_type}</span> <br>
                <span class='info_label small-text'>Color Name </span><br> <span>${data.color_name}</span> <br>
                <span class='info_label small-text'>L*a*b* Value </span><br>
                <span>${this.color.l} &nbsp;${this.color.a} &nbsp;${this.color.b}</span>`;

        this.open(html, showButtons);
    }

    openWithColorData(color, showButtons) {
        this.color = color;
        let html = `
            <span class='info_label small-text'>sRGB Value</span><br>
            <span>${color.rgb_r} &nbsp;${color.rgb_g} &nbsp;${color.rgb_b}</span><br>
            <span class='info_label small-text'>L*a*b* Value</span><br>
            <span>${color.l} &nbsp;${color.a} &nbsp;${color.b}</span>`;
        this.open(html, showButtons);
    }

    close(suppressEvent) {
        this.$element.hide();
        if (this.props.onClose && !suppressEvent) {
            this.props.onClose();
        }
    }

    static getActionButtons() {
        return `<div class='action-buttons'>
                    <div class="action-button">
                        <div class="c_icon c_icon_large i_check containCenter"></div> 
                    </div> 
                    <div class="action-button">
                        <div class="c_icon c_icon_large i_close containCenter"></div> 
                    </div> 
                </div>`;
    };

    static getColorPreview(color) {
        return `<div class='colorBox' style='background-color: rgb( ${color.rgb_r} , ${color.rgb_g} ,  ${color.rgb_b})'></div>`;
    };

    static getCloseButton() {
        return $.parseHTML(`<div class='box_close i_close containCenter'></div>`)[0];
    }

    static getContentWrapper() {
        return $.parseHTML(`<div class='content-wrapper medium-text'></div>`)[0];
    }
}
