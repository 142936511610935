import {findPointOnCircle, getRad} from "../functions.js";
import {
    DoubleSide,
    FontLoader,
    Geometry,
    Group,
    Line,
    LineBasicMaterial,
    Mesh,
    ShapeBufferGeometry,
    Vector3
} from "three";
import {Const, Debug} from "../utils/Utils";

const gridParams = {
    enableGrid: false,
    gridStep: 'Steps of 10',
    gridOpacity: 100,
    circularLines: false,
    gridAxis: false, // buvo false
    radius: Const.RADIUS,
};

export default class Grid {
    constructor(args) {
        this.enabled = false;
        this.steps = 10;
        this.opacity = 1;
        this.created = false;

        this.color = 0x333333;

        this.font1_url = Debug.getPath('./assets/fonts/Roboto/Roboto Condensed_Regular.json');
        this.font2_url = Debug.getPath('./assets/fonts/Roboto/Roboto_Bold.json');
        this.group = new Group();

        this.list = {
            lines: new Group(),
            labels: new Group(),
            steps: new Group(),
            axes: {},
            circular: new Group(),
            spherical: new Group(),
        };

        this.ranges = {
            a: 240,
            b: 240,
            l: 100,
        };

        this.config = {
            opacity: 0,
            axisLabel: true,
            circularLines: true,
            sphericalLines: true,
        };

        this.group.add(this.list.lines);
        this.group.add(this.list.labels);
        this.group.add(this.list.steps);
        this.group.add(this.list.circular);
        this.group.add(this.list.spherical);

        this.promises = [
            this.loadFont(this.font1_url),
            this.loadFont(this.font2_url),
            this.setMaterial(),
        ];

        Promise.all(this.promises).then(result => {
            this.font1 = result[0];
            this.font2 = result[1];
        });
    }

    setActive(active, config) {
        if (!config) return;
        this.config = config;


        this.setAxisLabelsVisible(this.config.axisLabel);
        this.setCircularLinesVisible(this.config.circularLines);
        this.setSphericalLinesVisible(this.config.sphericalLines);

        this.show(this.config.opacity > 0);
        this.setOpacity(this.config.opacity);

    }

    loadFont(url) {
        return new Promise(resolve => {
            new FontLoader().load(url, resolve);
        });
    }

    setMaterial() {
        this.material = new LineBasicMaterial({
            color: this.color,
            side: DoubleSide,
            // alphaTest: 0.95,
            transparent: false,
            // alphaTest: 0.1,
            depthTest: true,
            depthWrite: true,
        });
    }

    initCreate(force) {
        if (force == null) force = true;
        if (force) {
            Promise.all(this.promises).then(result => {

                this.remove(this.list.steps);
                this.remove(this.list.circular);
                this.create();
                this.group.visible = this.enabled;
                this.created = true;

            });
        }
    }

    create() {
        if (!this.created) {
            console.log('create lines and labels')
            this.createAxisLines();
            this.createAxisNames();
            this.createSphericalLines();
        }
        this.createAxisLabels();
        this.createCircularLines();
        this.setActive(true, this.config);
    }

    createSphericalLines() {
        var container = this.list.spherical;
        this.list.spherical.visible = this.config.sphericalLines;
        // var geometry = new SphereGeometry( gridParams.radius, this.steps*2, this.steps*2 );
        // var material = new MeshBasicMaterial( {color: this.color, wireframe: true, transparent: true, opacity: 0.5} );
        // var sphere = new Mesh( geometry, material );
        // container.add( sphere );

        var around_amm0 = 32;
        var around_amm = 32;
        var around_amm1 = 32 * 2;
        var L_amm0 = 10;
        var L_amm = 10;

        // horizontal splice lines
        for (var i = 0; i <= L_amm; i++) {
            var proc = i / L_amm;
            var yVal = 1 - (proc * 2 - 1) * Math.sign(proc - 0.5)
            var yValEased = (i < L_amm / 2) ? this.CircOut(yVal) : this.CircOut(yVal);
            var radius = L_amm * yValEased;
            for (var j = 0; j < around_amm1; j++) {
                var angle = j / around_amm1 * 360;
                var angle2 = j / around_amm1 * 360 + 360 / around_amm1;
                var y = i / L_amm * gridParams.radius * 2 - gridParams.radius;

                var x1 = radius * Math.sin(Math.PI * 2 * angle / 360);
                var z1 = radius * Math.cos(Math.PI * 2 * angle / 360);

                var x2 = radius * Math.sin(Math.PI * 2 * angle2 / 360);
                var z2 = radius * Math.cos(Math.PI * 2 * angle2 / 360);

                this.addLine(x1, y, z1, x2, y, z2, this.material, container);

                // this.addLine(x1, z1, y, x2, z2, y, this.material, container);
                // this.addLine(y, x1, z1, y, x2, z2, this.material, container);
            }
        }

        L_amm *= 5;
        // vertical circular lines
        for (var i = 0; i < around_amm; i++) {
            var angle = i / around_amm * 360;
            for (var j = 0; j < L_amm; j++) {
                var proc = j / L_amm;
                var yVal = 1 - (proc * 2 - 1) * Math.sign(proc - 0.5)
                var yValEased = this.CircOut(yVal);
                var radius = L_amm0 * yValEased;
                var y = j / L_amm * gridParams.radius * 2 - gridParams.radius;
                var y2 = (j + 1) / L_amm * gridParams.radius * 2 - gridParams.radius;

                var proc2 = (j + 1) / L_amm;
                var yVal2 = 1 - (proc2 * 2 - 1) * Math.sign(proc2 - 0.5)
                var yValEased2 = this.CircOut(yVal2);
                var radius2 = L_amm0 * yValEased2;


                var x1 = radius * Math.sin(Math.PI * 2 * angle / 360);
                var z1 = radius * Math.cos(Math.PI * 2 * angle / 360);

                var x2 = radius2 * Math.sin(Math.PI * 2 * angle / 360);
                var z2 = radius2 * Math.cos(Math.PI * 2 * angle / 360);

                // console.log(x1, y, z1, x2, y, z2);

                this.addLine(x1, y, z1, x2, y2, z2, this.material, container);
            }
        }


    }

    CircOut(a) {
        return Math.sqrt(1 - (a -= 1) * a)
    }

    CircIn(a) {
        return -(Math.sqrt(1 - a * a) - 1)
    }

    CircInOut(a) {
        return (a *= 2) < 1 ? -.5 * (Math.sqrt(1 - a * a) - 1) : .5 * (Math.sqrt(1 - (a -= 2) * a) + 1)
    }

    // circEase(){
    //     circular easing in - accelerating from zero velocity


    //     Math.easeInCirc = function (t, b, c, d) {
    //         t /= d;
    //         return -c * (Math.sqrt(1 - t*t) - 1) + b;
    //     };

    //     // circular easing out - decelerating to zero velocity

    //     Math.easeOutCirc = function (t, b, c, d) {
    //         t /= d;
    //         t--;
    //         return c * Math.sqrt(1 - t*t) + b;
    //     };
    // }

    createAxisLines() {
        console.log("createAxisLines");
        var container = this.list.lines;
        var r = gridParams.radius * 1.25;
        this.addLine(-r, 0, 0, r, 0, 0, this.material, container);
        this.addLine(0, -r, 0, 0, r, 0, this.material, container);
        this.addLine(0, 0, -r, 0, 0, r, this.material, container);
    }

    createAxisNames() {
        console.log("createAxisNames");
        var r = gridParams.radius * 1.25;
        var lr = r + 0.5; // label r
        var textSize = 0.55;
        var lf = this.font2; // label font
        var lm = this.material; // label material
        var lg = this.list.labels; // label group

        this.addText('-a', lf, [-lr, 0, 0], [-90, 0, 0], textSize, lm, lg, 'x');
        this.addText('a', lf, [lr, 0, 0], [-90, 0, 0], textSize, lm, lg, 'x');
        this.addText('b', lf, [0, 0, -lr], [-90, 0, -90], textSize, lm, lg, 'x');
        this.addText('-b', lf, [0, 0, lr], [-90, 0, -90], textSize, lm, lg, 'x');
        this.addText('L', lf, [0, lr, 0], [0, 0, 0], textSize, lm, lg, 'x');
    }

    createAxisLabels() {
        console.log("createAxisLabels");
        if (this.steps != 0) {
            // a & b
            var container = this.list.steps; // steps group
            var mu = 0.1;
            var total = this.ranges.a;
            var fs = 0.32;
            var sf = this.font1; // steps font
            var ll = 0.06;
            var amm = total / this.steps;
            for (var i = 0; i <= amm; i++) {
                // a
                var number = -total / 2 + i * this.steps;

                if (number != 0) {
                    number = number.toString()

                    var x = -gridParams.radius + gridParams.radius * 2 / amm * i;
                    var y = 0;
                    var z = 0;

                    this.addText(number, sf, [x, y, z - mu], [-90, 0, 0], fs, this.material, container, 'x')
                    this.addLine(x, y, z - ll, x, y, z + ll, this.material, container);

                    // b
                    var y = 0;
                    var x = 0;
                    var z = gridParams.radius - gridParams.radius * 2 / amm * i;
                    this.addText(number, sf, [x + mu, y, z], [-90, 0, -90], fs, this.material, container, 'x')
                    this.addLine(x - ll, y, z, x + ll, y, z, this.material, container);
                }
            }

            // L
            var total = this.ranges.l;
            var amm = total / this.steps;
            this.list.axes.l = new Group();
            container.add(this.list.axes.l)
            for (var i = 0; i <= amm; i++) {
                var number = i * this.steps;

                if (number != 50) {
                    number = number.toString()

                    var x = 0;
                    var y = -gridParams.radius + gridParams.radius * 2 / amm * i;
                    var z = 0;
                    this.addText(number, sf, [x + 0.35, y, z], [0, 0, 0], fs, this.material, this.list.axes.l, 'y')
                    this.addLine(x - ll, y, z, x + ll, y, z, this.material, this.list.axes.l);
                }
            }

            container.visible = this.config.axisLabel;
        }
    }

    createCircularLines() {
        console.log("createCircularLines");
        var container = this.list.circular;
        var dashDensity = 35;
        var m = this.material;
        // var dashSize = 1;
        // var gapSize = 1;
        if (this.steps != 0) {
            var ringAmm = this.ranges.a / this.steps / 2;

            for (var r = 1; r <= ringAmm; r++) {
                var rRadius = gridParams.radius * r / ringAmm;
                var dashAmm = Math.round(rRadius * dashDensity);
                var lpos = findPointOnCircle(0, 0, rRadius, 0);
                for (var d = 0; d < dashAmm; d++) {
                    var angle = getRad(d / dashAmm * 360);
                    var pos = findPointOnCircle(0, 0, rRadius, angle);
                    if (d % 2 == 1) {
                        this.addLine(lpos.x, 0, lpos.y, pos.x, 0, pos.y, this.material, container);
                    }
                    lpos = pos;
                }
            }

            this.setCircularLinesVisible(this.config.circularLines);
        }
        console.log("createCircularLines end")
    }

    setCircularLinesVisible(visible) {
        this.config.circularLines = visible;
        this.list.circular.visible = visible;
    }

    setAxisLabelsVisible(visible) {
        this.config.axisLabel = visible;
        this.list.steps.visible = visible;
    }

    setSphericalLinesVisible(visible) {
        this.config.sphericalLines = visible;
        this.list.spherical.visible = visible;
    }


    remove(group) {
        for (var i = group.children.length - 1; i >= 0; i--) {
            group.remove(group.children[i]);
        }
    }

    setGridSteps(mode) {
        switch (mode) {
            case 'none':
                this.steps = 0;
                break;
            case 'Steps of 10':
                this.steps = 10;
                break;
            case 'Steps of 20':
                this.steps = 20;
                break;
        }
    }

    show(mode) {
        this.initCreate(!this.created);
        this.enabled = mode;
        this.group.visible = mode;
    }

    setOpacity(value) {
        this.config.opacity = value;
        this.group.traverse(function (node) {
            if (node.material) {
                node.material.opacity = value/100;
                node.material.transparent = true;
            }
        });
    }

    update(mode, value) {
        switch (mode) {
            case 'steps':
                this.setGridSteps(value);
                this.initCreate();
                break;
            case 'show':
                this.show(value);
                break;
            case 'opacity':
                this.setOpacity(value);
                break;
            case 'camera align':
                if (this.enabled) {
                    this.alignToCamera(value);
                }
                break;
            case 'show circular':
                this.setCircularLinesVisible(value);
                break;
            case 'show axis':
                this.setAxisLabelsVisible(value);
                break;
            case 'show spherical':
                this.setSphericalLinesVisible(value);
                break;
        }
    }

    addText(text, font, pos, rot, size, material, container, align) {
        var shapes = font.generateShapes(text, size);
        var geometry = new ShapeBufferGeometry(shapes);

        var dPos = [0, 0, 0];
        geometry.computeBoundingBox();
        if (align == 'x') {
            dPos[0] = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        } else if (align == 'y') {
            dPos[1] = -0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
        }
        geometry.translate(dPos[0], dPos[1], 0);

        text = new Mesh(geometry, material);
        text.position.set(pos[0], pos[1], pos[2]);
        text.rotation.set(getRad(rot[0]), getRad(rot[1]), getRad(rot[2]));
        container.add(text);
        return text;
    }

    addLine(x1, y1, z1, x2, y2, z2, material, container) {
        var lineGeo = new Geometry();
        lineGeo.vertices.push(
            new Vector3(x1, y1, z1),
            new Vector3(x2, y2, z2),
        );
        var lineMesh = new Line(lineGeo, material);
        container.add(lineMesh);
    }

    alignToCamera(cPos) {
        // align labels
        // var list = this.list.labels.children;
        // for(var i=0; i<list.length; i++){
        //     list[i].lookAt( cPos );
        // }
        console.log();
        var labelL = this.list.labels.children[this.list.labels.children.length - 1];
        labelL.lookAt(cPos);

        // align axes numbers
        if (this.config.axisLabel) {
            let l = this.list.axes.l;
            l.rotation.y = Math.atan(cPos.x / cPos.z) + ((cPos.z > 0) ? 0 : getRad(180));
        }
    }

}

export const grid = new Grid();