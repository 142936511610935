import {BufferAttribute, BufferGeometry, Color, RepeatWrapping, ShaderMaterial, TextureLoader, Vector3} from "three";
import {Points} from '../threejs-patches/Points';

import {assets} from "../AssetLoader";
import {getPointOnScreen, height, scene} from "../Scene";
import {spherical_image} from "../spherical_image";
import {xyz2rgb} from "../functions";

import PointCloudDot from "./PointCloudDot";
import LabColor from "./LabColor";

import vertexNew from '../shaders/vertex_new.vert';
import fragmentNew from '../shaders/fragment_new.frag';

export class PointCloud {
    constructor(points, type, config) {
        this.createSnapPoints = this.createSnapPoints.bind(this);
        this.type = type;
        this.enabled = true;
        this.points = points || [];
        this.name = config.name;
        this.id = config.id || type;

        this.size = config.size || 60;

        this.opacity = config.opacity / 100 || 1;
        this.threeD = config.threeD || false;
        this.menuActive = config.menuActive || false;

        // When picking dots with mouse, adjust the active clickable area.
        this.thresholdMultiplier = config.thresholdMultiplier || 1;

        this.onPointsUpdated = () => console.log('onPointsUpdated not assigned!!!');
        this.createCloud();
    }

    createCloud() {
        this.createTextures();
        this.prepareCloud();
        this.cloud = new Points(this.geometry, this.material, this.thresholdMultiplier);
        this.cloud.userData = this;
        this.cloud.name = name;
        scene.add(this.cloud);
    }

    createTextures() {
        this.texture3d = new TextureLoader().load(spherical_image.png);
        this.texture3d.wrapS = RepeatWrapping;
        this.texture3d.wrapT = RepeatWrapping;

        this.texture2d = assets['particle8'];

        this.texture = this.threeD ? this.texture3d : this.texture2d;

        this.material = new ShaderMaterial({
            opacity: this.opacity,
            uniforms: {
                opacity: {value: this.opacity},
                amplitude: {value: 1.0},
                color: {value: new Color(0xffffff)},
                pointTexture: {value: this.texture},
                scale: {value: 1},
                scaleFactor: {value: height / 2},
                size: {value: this.size},
                diffuse: {value: new Color(0xffffff)},
            },

            transparent: true,
            vertexShader: vertexNew,
            fragmentShader: fragmentNew,

        });
        this.material.isPointsMaterial = true;
        this.material.color = new Color(0xffffff);
        this.material.size = this.size;
        this.material.scale = 1;

        this.geometry = new BufferGeometry();

    }

    update(points) {
        this.points = points;
        this.prepareCloud();
    }

    updatePoint(position, index) {
        this.points[index * 3 + 0] = position.x;
        this.points[index * 3 + 1] = position.y;
        this.points[index * 3 + 2] = position.z;
        this.prepareCloud();
    }

    addColorDot(dot) {
        this.addColorDots([dot.x, dot.y, dot.z]);
        return this.getCloudDot(this.count - 1);
    }

    addColorDots(dots) {
        let points = this.points.concat(dots);
        this.update(points);
    }

    merge(cloud) {
        this.addColorDots(cloud.points);
        cloud.clear();
    }

    clear() {
        this.update([]);
    }

    getCloudDot(index) {
        if (index < 0) {
            return new PointCloudDot(this.count + index, this);
        } else {
            return new PointCloudDot(index, this);
        }
    }

    prepareCloud() {
        this.count = this.points.length / 3;

        let positions = new Float32Array(this.count * 3);
        positions.set(this.points);

        let colors = new Float32Array(this.count * 3);
        let sizes = new Float32Array(this.count);
        let indices = new Uint16Array(this.count);

        let color = new Color();
        for (let i = 0; i < positions.length / 3; i++) {

            let rgb = xyz2rgb(positions[i * 3], positions[i * 3 + 1], positions[i * 3 + 2]);
            color.setRGB(rgb.r / 255.0, rgb.g / 255.0, rgb.b / 255.0);
            color.toArray(colors, i * 3);
            sizes[i] = this.size;
            indices[i] = i;
        }

        this.positions = new BufferAttribute(positions, 3);
        this.colors = new BufferAttribute(colors, 3);
        // this.sizes = new BufferAttribute(sizes, 1);
        this.index = new BufferAttribute(indices, 1);

        this.geometry.setIndex(this.index);
        this.geometry.setAttribute('position', this.positions);
        this.geometry.setAttribute('ca', this.colors);
        // this.geometry.setAttribute('size', this.sizes);

        this.geometry.computeBoundingSphere();
        this.onPointsUpdated();
    }

    getPosition(index) {
        let point = new Vector3(0, 0, 0);
        point.x = this.positions.getX(index);
        point.y = this.positions.getY(index);
        point.z = this.positions.getZ(index);
        return point;
    }

    getColor(index) {
        return LabColor.fromXYZ(
            this.positions.getX(index),
            this.positions.getY(index),
            this.positions.getZ(index)
        );
    }

    createSnapPoints() {
        let snapPoints = [];

        for (let i = 0; i < this.count; i++) {
            let point = this.getPosition(i);
            let pos = getPointOnScreen(this.cloud, point);
            snapPoints.push({...pos, object: this.getCloudDot(i)});
        }
        return snapPoints;
    }


    dispose() {
        this.geometry.dispose();
        this.material.dispose();
        this.texture2d.dispose();
        this.texture3d.dispose();
        scene.remove(this.cloud);
    }

    set3D(threeD) {
        this.threeD = threeD;
        this.texture = threeD ? this.texture3d : this.texture2d;
        this.material.uniforms.pointTexture.value = this.texture;
    }

    setSize(size) {
        this.size = size;
        this.material.size = size;
        this.onSizeChanged(this);
    }

    setOpacity(opacity) {
        this.opacity = opacity / 100;
        this.material.opacity = this.opacity;
    }

    setEnabled(enabled) {
        this.enabled = enabled;
        this.cloud.visible = enabled;
        this.onPointsUpdated();
    }
}

