import {connectionIndicator, middleDotIndicator, selectionIndicator} from "../AssetLoader";

class SelectionManager {
    constructor() {
        this.refresh = this.refresh.bind(this);
    }

    showIndicator(indicator, dot) {
        indicator.scale.set(dot.scale, dot.scale, dot.scale);
        indicator.material.opacity = 1;
        indicator.position.copy(dot.position);
    }

    hideIndicator(indicator) {
        indicator.material.opacity = 0;
    }


    refresh() {
        let s;
        if (this.selectedDot) {
            s = this.selectedDot.scale;
            selectionIndicator.scale.set(s, s, s);
        }

        if (this.connectedDot) {
            s = this.connectedDot.scale;
            connectionIndicator.scale.set(s, s, s);
        }

        if (this.middleDot) {
            s = this.middleDot.scale;
            middleDotIndicator.scale.set(s, s, s);
        }
    }

    setSelectedDot(dot) {
        if (this.selectedDot) {
            this.hideIndicator(selectionIndicator);
        }

        this.setConnectedDot(null);
        this.setMiddleDot(null);
        this.selectedDot = dot;

        if (dot) {
            this.showIndicator(selectionIndicator, dot);
        }
    }

    setConnectedDot(dot) {
        if (this.connectedDot) {
            this.hideIndicator(connectionIndicator);
        }

        this.connectedDot = dot;

        if (dot) {
            this.showIndicator(connectionIndicator, dot);
        }
    }

    setMiddleDot(dot) {
        if (this.middleDot) {
            this.hideIndicator(middleDotIndicator);
        }

        this.middleDot = dot;

        if (dot) {
            this.showIndicator(middleDotIndicator, dot);
        }
    }
}

const selectionManager = new SelectionManager();
window.selectionManager = selectionManager;
export default selectionManager;