import {colorDots} from "../core/ColorDots";
import {PointCloud} from "../core/PointCloud";
import {Const, Debug, Utils} from "../utils/Utils";
import {DotControls} from "../ui/ui";

export default class ColorSphere {
    constructor(args) {
        this.setActive = this.setActive.bind(this);
        this.density = 30;
        this.distribution = 'spherical';
        this.cloud = null;
    }

    createParticles() {
        let points = this.getParticlePositions();
        this.cloud = new PointCloud(points, Const.COLOR_SPHERE, Debug.config[Const.COLOR_SPHERE]);

        colorDots.addCloud(this.cloud);
    }

    getParticlePositions() {
        let positions;
        let s = Const.RADIUS;
        switch (this.distribution) {
            case 'random':
                let particleCount = Math.round(150000 * this.density / 100);
                positions = Utils.getRandomDotPositions(particleCount, s);
                break;
            case 'spherical':
                positions = Utils.getSphericalDotPositions(this.density, s);
                break;
        }
        return positions;
    }

    updateParticles() {
        let translateArray = this.getParticlePositions();
        this.cloud.update(translateArray);
    }

    setActive(active) {
        if (active && !this.cloud) {
            this.distribution = Debug.config[Const.COLOR_SPHERE].type || "spherical";
            this.createParticles();
        }

        if (this.cloud) {
            this.cloud.setEnabled(active);
        }
        DotControls.setControlValuesFromConfig($('#ui_c_sphere'), Debug.config[Const.COLOR_SPHERE]);
    }

    updateVal(mode, e) {
        switch (mode) {
            case 'distribution':
                this.distribution = e;
                this.updateParticles();
                break;
            case 'density':
                this.density = e;
                this.updateParticles();
                break;
        }
    }
}