import {Plane, Raycaster, Vector2, Vector3} from "three";
import {raycaster, renderer} from "../Scene";
import PointCloudDot from "../core/PointCloudDot";

class CastResult {
    constructor(intersect) {
        if (intersect) {
            this.intersect = intersect;
            this.index = intersect.index;
            this.cloud = intersect.object.userData;
            this.hit = true;
        } else {
            this.hit = false;
        }
    }

    getPointCloudDot() {
        return this.cloud.getCloudDot(this.index);
    }

    _getMaterial() {
        if (Array.isArray(this.intersect.object.material)) {
            return this.intersect.object.material[this.intersect.face.materialIndex];
        } else {
            return this.intersect.object.material;
        }
    }

    equals(int) {
        if (int) {
            return int.cloud === this.cloud && int.index === this.index;
        } else {
            return false;
        }
    }
}

export default class Ray {
    constructor(_cam, domElement) {
        this.domElement = domElement;
        this.cam = _cam;
        this.ray = new Raycaster();
        this.plane = new Plane(new Vector3(0, 0, 1), 0);
        this.ray.params.Points.threshold = 0.25 / 60 * 60;
    }

    prepareRay(event) {
        let mouse = new Vector2();
        mouse.x = (event.clientX / this.domElement.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / this.domElement.clientHeight) * 2 + 1;

        this.ray.setFromCamera(mouse, this.cam);
    }

    intersectPlane(event, normal = null, coplanarPoint = null) {
        this.prepareRay(event);
        let result = new Vector3();

        if (normal && coplanarPoint) {
            this.plane.setFromNormalAndCoplanarPoint(normal, coplanarPoint);
        }

        this.ray.ray.intersectPlane(this.plane, result);
        return result;
    }

    rayCastObject(event, object) {
        this.prepareRay(event);
        let intersects = this.ray.intersectObject(object, true);
        if (typeof intersects[0] !== 'undefined') {
            return intersects[0].point;
        } else {
            return null;
        }
    }

    rayCastObjects(event, objects) {
        this.prepareRay(event);
        let intersects = this.ray.intersectObjects(objects, true);
        if (typeof intersects[0] !== 'undefined') {
            return new CastResult(intersects[0]);
        } else {
            return new CastResult();
        }
    }
}