import {csvData} from "../core/CsvData";
import {colorDots} from "../core/ColorDots";
import {PointCloud} from "../core/PointCloud";
import {Const, Customer, Debug, Utils} from "../utils/Utils";
import UI, {DotControls} from "../ui/ui";
import userColors from "./UserColors";

export default class ColorData {
    constructor(args) {
        this.setActive = this.setActive.bind(this);
        this.file = {
            loadFile: function () {
                document.getElementById('myInput').click();
            }
        };

        this.csvFileLoaded = false;
        this.initialized = false;
        this.groups = [];
        $('#ui_c_data .ui_lines').hide();
        this.addEvents();
    }

    downloadFile() {
        csvData.download();
    }

    setActive(active) {
        if (!this.initialized) {
            this.initialized = true;
            this.init();
        }

        this.groups.forEach(group => {
            group.setEnabled(active);
        });

        let config = Debug.config[Const.COLOR_DATA];
        DotControls.setControlValuesFromConfig($('#ui_c_data'), {...config, ...config.data});

        userColors.setActive(active);
        userColors.setDotSize(config.data.size);
    }

    init() {
        let config = Debug.config[Const.COLOR_DATA];
        if (config && config.data && !config.data.useCsvData) {
            // Load default color sphere based on parameters.
            let points = Utils.getSphericalDotPositions(config.data.density, Const.RADIUS);
            let cloud = this.createPointGroup(points, config);
            cloud.setSize(config.data.size);
        } else {
            // Load default cvs data of the customer.
            let url = Customer.colorDataPath;
            this.load(url);
        }
    }

    addEvents() {
        let _this = this;

        let el = document.getElementById("myInput");

        el.onchange = function (e) {
            var tgt = e.target || window.event.srcElement,
                files = tgt.files;

            if (FileReader && files && files.length) {
                let fr = new FileReader();
                fr.onload = () => {
                    let url = fr.result;
                    _this.load(url, file.name);
                    $("#myInput").val('');
                };
                let file = files[0];
                fr.readAsDataURL(file);
            }
        };
    }

    createPointGroup(points, config) {
        let cloud = new PointCloud(points, Const.COLOR_DATA, config);
        this.groups.push(cloud);
        colorDots.addCloud(cloud);
        return cloud;
    }

    load(url, fileName) {
        let self = this;
        self.groups = [];
        let config = Debug.config[Const.COLOR_DATA];

        csvData.load(url, fileName,
            csv => {
                UI.addCsvGroup({mode: "clean"});
                $('#ui_c_data .scene_controls').hide(); // Hide default controls
                $('#ui_c_data .ui_lines').show(); // Show container for row controls
                colorDots.clear(Const.COLOR_DATA);
                userColors.clear();

                // Add UI rows
                csv.groupNames.forEach(groupName => {
                    UI.addCsvGroup({
                        mode: "add",
                        name: groupName,
                        id: groupName,
                        enabled: 1,
                        opacity: config.opacity,
                        size: config.size
                    });
                });

                for (let groupName in csv.groups) {
                    let group = csv.groups[groupName];

                    let points = new Array(group.length * 3);
                    let radius = Const.RADIUS;
                    group.forEach((row, i) => {
                        points[i * 3 + 0] = row.x * radius;
                        points[i * 3 + 1] = row.y * radius;
                        points[i * 3 + 2] = row.z * radius;
                    });
                    let groupConfig = {...config, id: groupName};
                    self.createPointGroup(points, groupConfig);
                }

                userColors.setDotSize(config.size);
            }
        );
    }
}