import {VertexColors} from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "../threejs-patches/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";
import {height, scene, width} from "../Scene";

class PointConnector {
    constructor() {
        this.lines = [];
        this.unusedLines = [];
        this.colorFrom = 0.6;
        this.colorTo = 0.0;
        this.colorDiff = this.colorFrom - this.colorTo;
    }

    getLine() {
        let line;
        if (this.unusedLines.length > 0) {
            line = this.unusedLines.pop();
        } else {
            line = this.createLine();
        }

        this.lines.push(line);
        return line;
    }

    createLine() {
        let geometry = new LineGeometry();
        let material = new LineMaterial({
            color: 0xffffff,
            linewidth: 5,
            vertexColors: VertexColors,
            dashed: false,
            startSize: 0.5,
            endSize: 0.5,
        });
        material.resolution.set(width, height); // resolution of the viewport


        let line = new Line2(geometry, material);
        line.scale.set(1, 1, 1);
        scene.add(line);
        return line;
    }


    lineBetweenPointsMulti(...points) {
        let point1 = points[0];
        let point2;

        let lineCount = points.length - 1;

        let colorTo;
        let colorFrom = this.colorFrom;
        let colorDiff = this.colorDiff / lineCount;

        let clear = true;

        for (let i = 1; i < points.length; i++) {

            point2 = points[i];
            colorTo = colorFrom - colorDiff;

            this.lineBetweenPoints(point1, point2, clear, colorFrom, colorTo);

            clear = false;
            colorFrom = colorTo;
            point1 = point2;
        }
    }

    lineBetweenPoints(p1, p2, clear = true, colorFrom = this.colorFrom, colorTo = this.colorTo) {
        if (clear) {
            this.hideLines();
        }
        let line = this.getLine();

        let positions = [p1.position.x, p1.position.y, p1.position.z, p2.position.x, p2.position.y, p2.position.z];
        let colors = [colorFrom, colorFrom, colorFrom, colorTo, colorTo, colorTo, colorTo];

        line.material.startSize = p1.scale;
        line.material.endSize = p2.scale;

        let geometry = line.geometry;
        geometry.setPositions(positions);
        geometry.setColors(colors);
        line.computeLineDistances();
        line.visible = true;
    }


    hideLines() {
        this.lines.forEach(line => {
            line.visible = false;
            this.unusedLines.push(line);
        });

        this.lines = [];
    }
}

let pointConnector = new PointConnector();
export default pointConnector;