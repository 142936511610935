import {TextureLoader} from "three";

export default class SphericalImage {

    constructor(args) {
        var _this = this;
        this.created = false;

        this.size = { w: 256, h: 256, r: 109 };

        this.cAmm = 6;

        this.ec = {
            // refl: 0,
        	circles: [],
        };

        // this.envUrl = "images/environment2.jpg";

        this.texLoader = new TextureLoader();
        // this.envMap = new TextureLoader().load( "images/environment2.jpg" );

        // this.newImg = new Image;
        // this.newImg.onload = function() {

            // _this.envMap = this.src;

            _this.gui();
            _this.init();
            _this.draw();
            _this.getPNG();

            _this.created = true;
            console.log("map texture created")

        // }
        // this.newImg.src = this.envUrl;


        // this.texLoader.load(
        //     _this.envUrl,
        //     function ( texture ) {
        //         _this.envMap = texture;

        //         _this.gui();
        //         _this.init();
        //         _this.draw();
        //         _this.getPNG();

        //     },
        // );


    }

    gui(){

		// this.gui = new dat.GUI();

        // this.gui.add(this.ec, 'refl', 0, 1 ).name('reflectivity').onChange(this.onGUIupdate);

		for(var i=0; i<this.cAmm; i++){

			// var f = this.gui.addFolder('Circle ' + i);

			this.ec.circles.push( { 
				// folder: f, 
				enable: false,
				x: this.size.w/2,
				y: this.size.h/2,
                color_1: 1,
                // color_2: 0,
                r1: 0,
                r2: 100,
			} );

	        // this.gui.add(this.ec.circles[i], 'enable' ).name('enable').onChange(this.onGUIupdate);
	        // this.gui.add(this.ec.circles[i], 'x', 0, this.size.w).onChange(this.onGUIupdate);
         //    this.gui.add(this.ec.circles[i], 'y', 0, this.size.w).onChange(this.onGUIupdate);
         //    this.gui.add(this.ec.circles[i], 'color_1', 0, 1).name('alpha').onChange(this.onGUIupdate);
         //    this.gui.add(this.ec.circles[i], 'r1', 0, 200).onChange(this.onGUIupdate);
         //    this.gui.add(this.ec.circles[i], 'r2', 0, 200).onChange(this.onGUIupdate);

		}

        // -= 0 =-
        this.ec.circles[0].enable = true;
        this.ec.circles[0].x = 171;
        this.ec.circles[0].y = 93;
        this.ec.circles[0].color_1 = 1;
        this.ec.circles[0].r1 = 30;
        this.ec.circles[0].r2 = 200;

        // -= 1 =-
        this.ec.circles[1].enable = true;
        this.ec.circles[1].x = 171;
        this.ec.circles[1].y = 93;
        this.ec.circles[1].color_1 = 1;
        this.ec.circles[1].r1 = 30;
        this.ec.circles[1].r2 = 200;

        // -= 2 =-
        this.ec.circles[2].enable = true;
        this.ec.circles[2].x = 171;
        this.ec.circles[2].y = 93;
        this.ec.circles[2].color_1 = 1;
        this.ec.circles[2].r1 = 30;
        this.ec.circles[2].r2 = 200;

        // // -= 3 =-
        // this.ec.circles[3].enable = true;
        // this.ec.circles[3].x = 0;
        // this.ec.circles[3].y = 256;
        // this.ec.circles[3].color_1 = 0.5;
        // this.ec.circles[3].r1 = 91;
        // this.ec.circles[3].r2 = 200;

        // // -= 4 =-
        // this.ec.circles[4].enable = true;
        // this.ec.circles[4].x = 176;
        // this.ec.circles[4].y = 80;
        // this.ec.circles[4].color_1 = 1;
        // this.ec.circles[4].r1 = 0;
        // this.ec.circles[4].r2 = 9;

        // // -= 5 =-
        // this.ec.circles[5].enable = true;
        // this.ec.circles[5].x = 176;
        // this.ec.circles[5].y = 83;
        // this.ec.circles[5].color_1 = 0.8;
        // this.ec.circles[5].r1 = 0;
        // this.ec.circles[5].r2 = 102;

    }

    onGUIupdate(){
        spherical_image.redraw();
    }

    update(){
        if(this.created) this.redraw();
    }

    redraw(){
        console.log("redraw")

        this.ctx.clearRect(0, 0, spherical_image.size.w, spherical_image.size.h);
        this.draw();
        this.getPNG();
        

        this.texLoader.load(
            this.png,
            function ( texture ) {
                uniforms.map.value = texture;
            },
        );

        // createParticles();

    }

    drawCircles(){
        for(var i=0; i<this.ec.circles.length; i++){
            var c = this.ec.circles[i];
            // console.log( c )

            if( c.enable ){
                // console.log("enabled")

                var x = c.x;
                var y = c.y;
                var c1 = c.color_1*255;
                var c2 = 0;

                var grd = this.ctx.createRadialGradient(x, y, c.r1, x, y, c.r2);
                grd.addColorStop(0, "rgb("+c1+","+c1+","+c1+")");
                grd.addColorStop(1, "rgb("+c2+","+c2+","+c2+")");
                this.ctx.fillStyle = grd;
                this.d_arc();

            }
        }
    }

    init(){

    	this.canvas = document.createElement('canvas');
		this.canvas.width = this.size.w;
		this.canvas.height = this.size.h;
		this.canvas.style.zIndex = 8;
		this.canvas.style.position = "absolute";
		this.canvas.style.backgroundColor = "rgba(50, 50, 50, 0.25)"

		this.ctx = this.canvas.getContext("2d");

		// var body = document.getElementsByTagName("body")[0];
		// body.appendChild(this.canvas);

    }

    draw(){


    	// blur
    	this.ctx.filter = 'blur(2px)';

    	// white circle
		this.ctx.fillStyle = '#000000';
        // this.ctx.fillStyle = '#FFFFFF';
        this.d_arc();

        // basic circle fade
        this.ctx.globalCompositeOperation = 'screen';
        this.drawCircles();


        // var horVal = 0;
        // if(camAngle.hor<0.5){
        //     horVal = camAngle.hor*2;
        // } else {
        //     horVal = 2 - camAngle.hor*2;
        // }
        // horVal = (0.5+camAngle.hor) * this.size.w;
        // var horVal = camAngle.hor * 512;

        
        // console.log( horVal )
        // console.log( this.newImg )
        // this.ctx.globalAlpha = this.ec.refl;
        // this.ctx.drawImage(this.newImg, horVal-512, 0);
        // this.ctx.drawImage(this.newImg, horVal, 0);
        // this.ctx.globalAlpha = 1;

        this.maskCircle();

    }

    d_arc(){
    	var x = this.size.w/2;
    	var y = this.size.h/2;

        this.ctx.beginPath();
    	this.ctx.arc(x, y, this.size.r, 0, Math.PI*2);
        this.ctx.fill();
    }

    maskCircle(){
    	var x = this.size.w/2;
    	var y = this.size.h/2;

	    this.ctx.save();
	    this.ctx.globalCompositeOperation = 'destination-in';
	    this.ctx.beginPath();
	    this.ctx.arc(x, y, this.size.r, 0, 2 * Math.PI, false);
	    this.ctx.fill();
	    this.ctx.restore();
    }

    getPNG(){
    	this.png = this.canvas.toDataURL();
    	// console.log( this.png )
    }

}

export const spherical_image = new SphericalImage();