import ColorSphere from "./clouds/ColorSphere.js";
import {intro} from "./ui/intro.js";
import {assets} from "./AssetLoader";
import {prepareThree, scene} from "./Scene";
import ColorData from "./clouds/ColorData";
import {grid} from "./clouds/Grid";
import ImageData from "./clouds/image";
import {Const, Customer, Debug} from "./utils/Utils";
import UI from "./ui/ui";
import Intro from "./ui/intro";
import userColors from "./clouds/UserColors";

window.addEventListener("wheel", function (event) {
    event.preventDefault();
}, {passive: false});


let configRetrieved = false;
let assetsLoaded = false;

function initApp() {
    if (assetsLoaded && configRetrieved) {

        let intro = new Intro();
        intro.init();

        prepareThree();

        userColors.init();

        let sphere = new ColorSphere();
        let imageData = new ImageData();
        let colorData = new ColorData();

        let ui = new UI(imageData, colorData, sphere);

        //
        window.app = sphere;
        window.image_data = imageData;
        window.csv = colorData;
        window.ui = ui;
        //

        scene.add(grid.group);

        switch (Debug.config.initialTab) {
            case Const.COLOR_SPHERE:
                break;
            case Const.COLOR_DATA:
                break;
            case Const.IMAGE_DATA:
                imageData.load();
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    let configUrl = Customer.configPath;

    $.getJSON(configUrl, (data) => {
        Debug.config = data;
        configRetrieved = true;
        initApp();
    });

    assets.addListener(() => {
        assetsLoaded = true;
        initApp();
    });

}, false);