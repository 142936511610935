import {inputHandler} from "./InputHandler";

export class Draggable {
    constructor(parent, draggable, options) {

        // $(parent).on('pointerdown', draggable, this.pointerDown.bind(this));
        // $('body').on('pointermove', this.pointerMove.bind(this));
        // $('body').on('pointerup', this.pointerUp.bind(this));

        this.targetElements = $(`${parent} ${draggable}`).toArray();
        this.element = draggable;
        this.snapped = false;
        this.currentElement = null;
        this.options = options;
        this.isDragging = false;

        inputHandler.addHandler(this);
    }

    pointerDown(e) {
        if (!this.targetElements.includes(e.target)) {
            return false;
        }

        e.preventDefault();
        this.setSnapped(false);
        this.currentElement = e.target;
        this.$currentElement = $(e.target);
        this.isDragging = true;

        this.origLeft = this.$currentElement.css('left');
        this.origTop = this.$currentElement.css('top');

        this.origLeft = Number(this.origLeft.replace('px', ''));
        this.origTop = Number(this.origTop.replace('px', ''));

        this.offsetX = e.offsetX - this.$currentElement.width() / 2;
        this.offsetY = e.offsetY - this.$currentElement.height() / 2;
        this.clientX = e.clientX;
        this.clientY = e.clientY;

        if (this.options.dragStart) {
            this.options.dragStart({x: e.clientX, y: e.clientY, obj: this.currentElement});
        }

        this.createSnapPoints();
        return true;
    }

    pointerMove(e) {
        if (this.isDragging) {
            e.preventDefault();

            this.currentElement.classList.add('dragging');

            let x = e.clientX;
            let y = e.clientY;
            if (this.options.snap) {
                let snappedPos = this.snap({x: e.clientX, y: e.clientY});
                x = snappedPos.x;
                y = snappedPos.y;
            }

            let diffX = x - this.clientX + this.origLeft;
            let diffY = y - this.clientY + this.origTop;

            this.currentElement.style.left = `${diffX}px`;
            this.currentElement.style.top = `${diffY}px`;

            if (this.options.drag) {
                this.options.drag({x: e.clientX, y: e.clientY, obj: this.currentElement});
            }
            return true;
        }
        return false;
    }

    pointerUp(e) {
        if (this.isDragging) {
            e.preventDefault();

            if (!this.snapped) {
                this.$currentElement.animate({
                    left: this.origLeft,
                    top: this.origTop
                }, 500);
            } else {
                this.currentElement.style.left = `${this.origLeft}px`;
                this.currentElement.style.top = `${this.origTop}px`;
            }

            if (this.options.dragEnd) {
                this.options.dragEnd({x: e.clientX, y: e.clientY, obj: this.currentElement});
            }

            this.currentElement.classList.remove('dragging');

            this.currentElement = null;
            this.$currentElement = null;
            this.isDragging = false;
            return true;
        }
        return false;
    }

    gestureWheel(event) {
        return false;
    }


    createSnapPoints() {
        if (typeof this.options.snap === 'boolean') {
            this.snapPoints = $(this.options.snap).map((index, el) => {
                    return {x: el.offsetLeft, y: el.offsetTop};
                }
            ).get();
        }
        if (typeof this.options.snap === 'function') {
            this.snapPoints = this.options.snap();
        }
    }

    snap(point) {
        const shouldSnap = (point, closestSnapPoint) => {
            let threshold = 30;
            return (
                Math.abs(closestSnapPoint.x - point.x) < threshold &&
                Math.abs(closestSnapPoint.y - point.y) < threshold
            );
        };

        const diffToSnapPoint = (point, snapPoint) => {
            let diffX = Math.abs(snapPoint.x - point.x);
            let diffY = Math.abs(snapPoint.y - point.y);
            let diff = diffX * diffX + diffY * diffY;
            return diff;
        };

        let closest = this.snapPoints.reduce(function (prev, curr) {
            let diffPrev = diffToSnapPoint(point, curr);
            let diffCurrent = diffToSnapPoint(point, prev);

            return (diffCurrent > diffPrev) ? curr : prev;
        });

        if (shouldSnap(point, closest)) {
            this.setSnapped(closest);
            return {x: closest.x + this.offsetX, y: closest.y + this.offsetY}
        }
        this.setSnapped(null);
        return point;
    }

    setSnapped(snapped) {
        if (this.snapped === snapped) {
            return;
        }
        this.snapped = snapped;
    }
}
