import {Const, Debug} from "../utils/Utils";
import {PointCloud} from "../core/PointCloud";
import {colorDots} from "../core/ColorDots";
import selectionManager from "../core/SelectionManager";
import pointConnector from "../core/PointConnector";
import {ColorDotsInputHandler} from "../input/ColorDotsInputHandler";
import InfoPopup from "../ui/InfoPopup";
import {csvData} from "../core/CsvData";

class UserColors {
    constructor() {

    }

    init() {
        this.size = Debug.config[Const.COLOR_DATA].size;

        let config = {threeD: true, size: 30, menuActive: true, thresholdMultiplier:2};
        this.colorsBeingUpdated = new PointCloud(null, Const.COLOR_BEING_UPDATED, config);
        colorDots.addCloud(this.colorsBeingUpdated);

        config.size = this.size;
        this.userColors = new PointCloud(null, Const.USER_COLORS, config);
        colorDots.addCloud(this.userColors);
    }

    setActive(active) {
        this.userColors.setEnabled(active);
        this.colorsBeingUpdated.setEnabled(active);
    }

    setDotSize(size){
        this.size = size;
    }

    acceptUserDot() {
        //Set the size of created color dot to the default color data dot size.
        this.userColors.setSize(this.size);

        this.userColors.merge(this.colorsBeingUpdated);
        let dot = this.userColors.getCloudDot(-1);
        selectionManager.setSelectedDot(dot);

        csvData.addRow(dot, 'User Colors');


        InfoPopup.showPointData(dot, 1)
    }

    rejectUserDot() {
        pointConnector.lineBetweenPoints(ColorDotsInputHandler.sliderStartDot, ColorDotsInputHandler.sliderEndDot);
        this.clearIncompleteDots();
    }

    clearIncompleteDots() {
        selectionManager.setMiddleDot(null);
        this.colorsBeingUpdated.clear();
    }

    clear() {
        selectionManager.setSelectedDot(null);
        this.colorsBeingUpdated.clear();
        this.userColors.clear();
    }
}

let userColors = new UserColors();
export default userColors;