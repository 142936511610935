import {Color, Matrix4, Scene, Vector3, WebGLRenderer} from "three";
import {TAARenderPass} from "three/examples/jsm/postprocessing/TAARenderPass";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {Debug} from "./utils/Utils";
import InfoPopup from "./ui/InfoPopup";
import {grid} from "./clouds/Grid";
// import Stats from "stats.js";
import CamControl from "./CamControl";
import {CameraInputHandler} from "./input/CameraInputHandler";
import {inputHandler} from "./input/InputHandler";
import {connectionIndicator, middleDotIndicator, selectionIndicator} from "./AssetLoader";
import {colorDots} from "./core/ColorDots";
import {ColorDotsInputHandler} from "./input/ColorDotsInputHandler";
import Ray from "./utils/Ray";

export var width = window.innerWidth;
export var height = window.innerHeight;
export var camera, controls, renderer, scene;
export var container = document.getElementById('container');
export var raycaster;

let composer, rtime;
let timeout = false;
let delta = 200;

if (Debug.debug) {
    // window.stats = new Stats();
    // stats.showPanel(0);
    // document.getElementById('container').appendChild(stats.dom);
}

export function updateSceneBackground(darkness) {
    let bgColVal = Math.round(darkness / 100 * 255);
    scene.background = new Color("rgb(" + bgColVal + ", " + bgColVal + ", " + bgColVal + ")");

    let el = $("#logo > .logo_white");
    el.css("opacity", 1 - darkness / 100);
}

export function getPointOnScreen(obj, vec) {
    let canvas = renderer.domElement;
    obj.updateWorldMatrix(true, false);

    vec = obj.localToWorld(vec.clone());
    vec.project(camera);
    vec.x = (vec.x * .5 + .5) * canvas.clientWidth;
    vec.y = (vec.y * -.5 + .5) * canvas.clientHeight;
    vec.z = 0;

    return vec;
}

export function prepareThree() {
    scene = new Scene();
    window.scene = scene;

    scene.background = new Color(1, 1, 1);

    renderer = new WebGLRenderer({antialias: false});
    // renderer.gammaFactor = 2.2;
    // renderer.gammaOutput = true;
    // renderer.setClearColor(ec.bgColor, 1);
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    container.appendChild(renderer.domElement);

    // let stats = new Stats();
    // container.appendChild( stats.dom );

    let camOptions = {
        distance: 30.7,
        focusPos: new Vector3(0, 0, 0),
        distRange: {
            max: 110,
            min: 2
        },
        rotation: new Vector3(45, 23, 0),
        eyeSeparation: 0.3,
        damping: 0.2,
        fov: 50,
    };

    controls = new CamControl(camOptions, renderer.domElement);

    let colorDotsInputHandler = new ColorDotsInputHandler();
    let cameraInputHandler = new CameraInputHandler(renderer.domElement, controls);

    inputHandler.addHandler(colorDotsInputHandler);
    inputHandler.addHandler(cameraInputHandler);

    camera = controls.camera;

    raycaster = new Ray(camera, renderer.domElement);
    // let helper = new PlaneHelper(raycaster.plane, 30, 0xffff00);
    // scene.add(helper);


    window.renderer = renderer;
    window.camera = camera;
    window.controls = controls;
    window.addEventListener('resize', onWindowResize, false);
    // createEvents();

    scene.add(selectionIndicator, connectionIndicator, middleDotIndicator);

    postProcessing();
    render();
}


function postProcessing() {
    let taaRenderPass = new TAARenderPass(scene, camera);
    taaRenderPass.sampleLevel = 2;

    composer = new EffectComposer(renderer);
    composer.addPass(taaRenderPass);
}

const render = function (feed) {
    requestAnimationFrame(render);
    sortPoints();
    composer.render();
    animate(feed);
};


function sortPoints() {
    colorDots.getActiveGroups().forEach(group => {
        let cloud = group.cloud;

        let vector = new Vector3();

        // Model View Projection matrix

        let matrix = new Matrix4();
        matrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
        matrix.multiply(cloud.matrixWorld);

        var geometry = cloud.geometry;
        var index = geometry.getIndex();
        var positions = geometry.getAttribute('position').array;
        var length = positions.length / 3;

        var sortArray = [];

        for (var i = 0; i < length; i++) {

            vector.fromArray(positions, i * 3);
            vector.applyMatrix4(matrix);

            sortArray.push([vector.z, i]);

        }

        function numericalSort(a, b) {

            return b[0] - a[0];

        }

        sortArray.sort(numericalSort);

        var indices = index.array;

        for (var i = 0; i < length; i++) {

            indices[i] = sortArray[i][1];

        }

        geometry.index.needsUpdate = true;

    });

}

function animate(feed) {
    // if (Debug.debug) stats.begin();

    InfoPopup.updateMenuLayout(feed);
    controls.update();

    grid.update('camera align', camera.position);

    // if (Debug.debug) stats.end();
}


function onWindowResize() {
    updateScreenSize();
    controls.onWindowResize(width, height);
    renderer.setSize(width, height);

    rtime = new Date();
    if (timeout === false) {
        timeout = true;
        setTimeout(resizeend, delta);
    }
}

function resizeend() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeend, delta);
    } else {
        timeout = false;
        postProcessing();
    }
}

function updateScreenSize() {
    width = window.innerWidth;
    height = window.innerHeight;
}


export function resetDataSphere() {
    controls.reset();
}


