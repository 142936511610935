import {circleMenu} from "./CircleMenu.js";
import {deltaE} from "../functions.js";
import PopupBox from "./PopupBox.js";
import {csvData} from "../core/CsvData";
import selectionManager from "../core/SelectionManager";
import {Const} from "../utils/Utils";
import userColors from "../clouds/UserColors";

let particleInfo1 = new PopupBox('particleInfo', {
    onClose: () => {
        InfoPopup.closeAll(true);
        selectionManager.setSelectedDot(null);
    },
    top: 30
});

let deltaEInfo = new PopupBox('deltaEInfo', {
    onClose: () => {
        userColors.rejectUserDot();
        particleInfo2.close(true);
        InfoPopup.hideMenu();
    },
    prevBox: particleInfo1
});

let colorMixInfo = new PopupBox('colorMixInfo', {
    onClose: () => {
        userColors.rejectUserDot();
        particleInfo2.close(true);
        InfoPopup.hideMenu();
    },
    onApprove: () => {
        userColors.acceptUserDot();
        colorMixInfo.close();
    },
    onReject: () => {
        colorMixInfo.close();
    },
    prevBox: particleInfo1
});

let particleInfo2 = new PopupBox('particleInfo2', {
    onClose: () => {
        circleMenu.rollback();
        InfoPopup.showDeltaE();
    },
    prevBox: deltaEInfo
});


export default class InfoPopup {
    constructor() {

    }

    static showPointData(dot, popupIndex) {
        if (popupIndex === 1) {
            deltaEInfo.close(true);
            colorMixInfo.close(true);
            particleInfo2.close(true);
        }

        let particleInfo = (popupIndex === 2) ? particleInfo2 : particleInfo1;

        let data;
        if (dot.isColorData && csvData.loaded) {
            data = csvData.getRow(dot.groupName, dot.id);
            particleInfo.openWithCsvData(data);
        } else {
            data = dot.color;
            particleInfo.openWithColorData(data);
        }
    }

    static showDeltaE(fromDot, toDot) {
        let empty = '—.—';
        let delta = {deltaL: empty, deltaA: empty, deltaB: empty, deltaE: empty};
        if (fromDot && toDot) {
            delta = deltaE(fromDot.color.lab, toDot.color.lab);
        }

        let html = `<span class='info_label small-text'>dE</span> <br>
                    <span>${delta.deltaE}</span> <br>
                    <span class='info_label small-text'>Delta L*a*b Value</span> <br>
                    <span>${delta.deltaL} ${delta.deltaA} ${delta.deltaB}</span>`;

        particleInfo2.prevBox = deltaEInfo;
        deltaEInfo.open(html);
    }

    static showColorMix(fromDot, toDot, midDot) {
        particleInfo2.prevBox = colorMixInfo;
        colorMixInfo.openWithColorData(midDot.color, true);
    }

    static updateMenuLayout(feed) {
        circleMenu.update(feed);
    }

    static showMenu(dot) {
        if (circleMenu.currentId === dot.id && circleMenu.isOpen) {
            circleMenu.close();
        } else {
            circleMenu.open(dot.id, dot);
        }
    }

    static hideMenu() {
        if (circleMenu) {
            circleMenu.close();
        }
    }

    static closeAll(suppressEvent) {
        suppressEvent = true;
        particleInfo2.close(suppressEvent);
        particleInfo1.close(suppressEvent);
        deltaEInfo.close(suppressEvent);
        colorMixInfo.close(suppressEvent);

        this.hideMenu();
    }
}

window.updateLayout = InfoPopup.updateMenuLayout;