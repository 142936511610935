import {camera, getPointOnScreen, scene} from "../Scene";
import {BoxHelper, Sprite, Vector2, Vector3} from "three";
import {gsap} from "gsap";


import {SVG} from '@svgdotjs/svg.js'
import {Draggable} from '../input/Draggable.js';
import InfoPopup from "./InfoPopup";
import {colorDots} from "../core/ColorDots";
import selectionManager from "../core/SelectionManager";
import {Const} from "../utils/Utils";
import pointConnector from "../core/PointConnector";
import {ColorDotsInputHandler} from "../input/ColorDotsInputHandler";

let helerStripe = new Sprite();
let helperBox = new BoxHelper(helerStripe, 0xff0000);
helperBox.matrixAutoUpdate = true;
helperBox.scale.set(0.5, 0.5, 0.5);
helperBox.visible = false;
window.helper = helperBox;

const minCorner = new Vector3(-0.5, -0.5, -0.0);
const maxCorner = new Vector3(0.5, 0.5, -0.0);

class CircleMenu {
    constructor(node) {
        this.menu = node;
        this.isOpen = false;
        this.currentId = null;

        let itemCount = this.menu.childElementCount;
        let totalAngle = Math.PI / 2;
        this.angleStep = totalAngle / (itemCount - 1);
        this.startAngle = Math.PI / 4;

        this.animationRunning = false;
        this.initElements();
        this.initSVG();
    }

    initSVG() {
        let draw = SVG().addTo('#container').size('100%', "100%");

        this.lineGradient = draw.gradient('linear', function (add) {
            add.stop(0, '#666');
            add.stop(1, '#000');
        });
        this.lineGradientReverse = draw.gradient('linear', function (add) {
            add.stop(0, '#000');
            add.stop(1, '#666');
        });

        this.lineColor = '#666';
        this.line = draw.line(0, 0, 0, 0);
    }

    initElements() {
        let self = this;
        let arrows = $(this.menu).find('.arrow');
        arrows.css('transform', (index, oldval) => {
            let angle = (index * this.angleStep + this.startAngle) / Math.PI * 180 - 90;
            return `translateX(12px) rotate(${angle}deg) translateY(25px)`;
        });

        const draggable = new Draggable('.circular-menu', '.menu-item.draggable', {
            snap: colorDots.createSnapPoints,
            drag: (evt) => {
                self.drawLine(evt)
            },
            dragEnd: (evt) => {
                if (draggable.snapped) {
                    self.close(true);
                    self.connectTo(draggable.snapped.object);
                } else {
                    self.line.css('display', 'none');
                    self.drawLine(evt);
                }
            },
            dragStart: (evt) => {
                self.setSelectedItem(draggable.currentElement);
                self.update();

                self.line.css('display', 'block');
                self.drawLine(evt);
            }
        });
    }

    rollback() {
        this.removeConnection();
        this.isOpen = true;
    }

    setSelectedItem(item) {
        this.selectedItem = item;
        let name = $(item).data('name');
        switch (name) {
            case 'delta-e':
                InfoPopup.showDeltaE();
                break;
        }
    }

    connectTo(object) {
        this.line.css('display', 'none');

        let name = $(this.selectedItem).data('name');
        switch (name) {
            case 'delta-e':
                this.connectedTo = object;
                pointConnector.lineBetweenPoints(this.object, object);
                selectionManager.setConnectedDot(object);

                InfoPopup.showDeltaE(this.object, object);
                InfoPopup.showPointData(object, 2);
                break;
            case 'merge-colors':

                let midPoint = new Vector3();
                midPoint.lerpVectors(this.object.position, object.position, 0.5);

                let midColorDot = colorDots.getCloud(Const.COLOR_BEING_UPDATED).addColorDot(midPoint);
                ColorDotsInputHandler.sliderStartDot = this.object;
                ColorDotsInputHandler.sliderEndDot = object;

                selectionManager.setConnectedDot(object);
                selectionManager.setMiddleDot(midColorDot);

                pointConnector.lineBetweenPointsMulti(this.object, midColorDot, object);

                InfoPopup.showColorMix(this.object, object, midColorDot);
                InfoPopup.showPointData(object, 2);
                break;
        }
    }

    drawLine(evt) {
        let {x, y, width, height} = evt.obj.getBoundingClientRect();
        let x1 = this.position.x,
            y1 = this.position.y,
            x2 = x + width / 2,
            y2 = y + height / 2;

        let p1 = CircleMenu.getCircleEdge(new Vector2(x1, y1), new Vector2(x2, y2), this.size);
        this.line.stroke(this.lineColor);
        this.line.plot(p1.x, p1.y, x2, y2);
    };

    removeConnection() {
        this.connectedTo = null;
        pointConnector.hideLines();
        selectionManager.setConnectedDot(null);
    }

    createTween(pos, size) {
        let getScale = (e) => {
            let hoverScale = () => {
                if (e.matches(':hover') || e.classList.contains('dragging')) {
                    return 1.2;
                } else {
                    return 1;
                }
            };

            if (self.selectedItem) {
                if (self.selectedItem === e) {
                    return hoverScale();
                } else {
                    return 0;
                }
            } else {
                return hoverScale();
            }
        };

        let self = this;
        let duration = 0.5;
        let from = {
            scale: 0,
            x: 0, y: 0,
            duration: duration,
            overwrite: true,
        };

        if (!this.isOpen) {
            this.tween = gsap.to(this.menu.children, from);
            return
        }

        let to = {
            scale: function (index, target, targets) { //function-based value
                return getScale(target);
            },
            y: function (index, target, targets) { //function-based value
                return Math.sin(self.angleStep * index + self.startAngle) * -(size / 2 + getScale(target) * 38);
            },
            x: function (index, target, targets) { //function-based value
                return Math.cos(self.angleStep * index + self.startAngle) * -(size / 2 + getScale(target) * 38);
            },

            duration: duration,
            ease: "elastic.out(1, 0.5)",
            stagger: 0.1,
            paused: true,
            overwrite: true,
        };

        if (this.selectedItem) {
            this.tween = gsap.to(this.menu.children, to);
        } else {
            this.tween = gsap.fromTo(this.menu.children, from, to);
        }
    }

    getAnimationPosition(feed) {
        if (!feed) {
            this.animationStart = -1;
            return 0;
        }

        if (this.animationStart < 0) {
            this.animationStart = feed;
        }

        return (feed - this.animationStart) / 1000;
    }

    open(id, obj) {
        // window.obj = obj;
        this.removeConnection();
        this.selectedItem = null;
        this.object = obj;
        this.currentId = id;

        this.animationRunning = true;

        this.isOpen = true;
        this.update();

        let self = this;
        clearTimeout(this.aotoCloseTimer);
        this.aotoCloseTimer = setTimeout(() => {
            if (!self.selectedItem && self.isOpen) {
                self.close();
            }
        }, 3000);

    }

    update(feed) {
        // if (!Debug.debug) return;

        if (!this.object) return;
        if (!this.isOpen && !this.animationRunning) {
            return;
        }
        let time = this.getAnimationPosition(feed);
        let layoutParams = CircleMenu.calculateMenuLayoutParams(this.object);
        let {pos, size} = layoutParams;

        this.position = pos;
        this.size = size;

        if (size < 20) {
            this.getAnimationPosition();
            this.close(true);
        }

        this.createTween(pos, size);

        if (feed) {
            this.tween.seek(time);
            if (this.tween.ratio >= 1 && !this.isOpen && !this.connectedTo) {
                this.animationRunning = false;
            }
        }

        // this.menu.style.setProperty('--size', `${size / -2 - 38}px`);
        this.menu.style.left = `${Math.round(pos.x)}px`;
        this.menu.style.top = `${Math.round(pos.y)}px`;
        // this.menu.classList.add('open');

    }

    close(justMenu) {
        if (this.isOpen) {
            this.isOpen = false;
            this.animationRunning = true;
        }
        clearTimeout(this.aotoCloseTimer);

        if (!justMenu) {
            this.removeConnection();
            this.update();
        }
    }

    static calculateMenuLayoutParams(obj) {
        //TODO: do this once
        scene.add(helperBox);
        helperBox.scale.set(obj.scale, obj.scale, obj.scale);
        helperBox.position.set(0, 0, 0);
        helperBox.lookAt(camera.position);
        helperBox.position.copy(obj.position);


        let pos = getPointOnScreen(helperBox, new Vector3(0, 0, 0));
        let min = getPointOnScreen(helperBox, minCorner);
        let max = getPointOnScreen(helperBox, maxCorner);
        let pointSizeOnScreen = max.x - min.x;

        return {pos: pos, size: pointSizeOnScreen};
    }

    static getCircleEdge(p1, p2, size) {
        let [p11, p22] = [p1.clone(), p2.clone()];

        let length = p11.distanceTo(p22);
        p11.add((p22.sub(p11)).multiplyScalar(size / 2 / length));
        return {x: p11.x, y: p11.y};
    }
}

let menu = $('.circular-menu')[0];
export const circleMenu = new CircleMenu(menu);
