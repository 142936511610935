import {resetDataSphere} from "../Scene.js";
import {Const, Debug} from "../utils/Utils.js";
import selectionManager from "../core/SelectionManager";
import {grid} from "../clouds/Grid";
import InfoPopup from "./InfoPopup";
import {login} from "./LoginForm";
import {updateSceneBackground} from "../Scene";

export class DotControls {
    constructor(container) {
        this.container = container;

        this.type = container.find('.btn-group[alt="distribution"]'); //distribution ButtonGroup
        this.opacity = container.find('.ui_slider[alt="opacity"] input');
        this.density = container.find('.ui_slider[alt="density"] input');
        this.size = container.find('.ui_slider[alt="size"] input');
        this.threeD = container.find('.ui_switch[alt="3d"] input');
        this.axisLabel = container.find('.ui_switch[alt="axis"] input');
        this.circularLines = container.find('.ui_switch[alt="circular"] input');
        this.sphericalLines = container.find('.ui_switch[alt="spherical"] input');
    }

    static setControlValuesFromConfig(container, config) {
        let dotControls = new DotControls(container);
        dotControls.setFromConfig(config);
    }

    static setSliderValue(slider, value) {
        slider.val(value);
        slider.prop('value', value);
        slider.attr('value', value);

        let eye = slider.parent().children('.i_eye')[0];
        if (eye) {
            if (value > 0) {
                eye.classList.remove('active');
            } else {
                eye.classList.add('active');
            }
        }
    }

    static setSwitchValue(s, value) {
        s.prop('checked', value);
    }

    static setButtonGroup(group, activeButton) {
        group.find('button').removeClass('active');
        group.find('button[alt="'+activeButton+'"]').addClass('active');

    }

    setFromConfig(config) {
        for (let key in config) {
            let prop = this[key];
            let value = config[key];
            let capitalized = key.charAt(0).toUpperCase() + key.slice(1);
            if (prop) {
                this[`set${capitalized}`](value);
            }
        }
    }

    setType(value) { //Distribution
        DotControls.setButtonGroup(this.type,value);
    }

    setOpacity(value) {
        DotControls.setSliderValue(this.opacity, value);
    }

    setDensity(value) {
        DotControls.setSliderValue(this.density, value);
    }

    setSize(value) {
        DotControls.setSliderValue(this.size, value);
    }

    setThreeD(value) {
        DotControls.setSwitchValue(this.threeD, value);
    }

    setAxisLabel(value) {
        DotControls.setSwitchValue(this.axisLabel, value);
    }

    setCircularLines(value) {
        DotControls.setSwitchValue(this.circularLines, value);
    }

    setSphericalLines(value) {
        DotControls.setSwitchValue(this.sphericalLines, value);
    }
}

class UiTab {
    constructor(name, content, tab, object, config) {
        this.name = name;
        this.content = $(content);
        this.tab = $(tab);
        this.setActive = object.setActive;
        this.config = Debug.config[name] || {};

        this.isGrid = name === Const.COORDINATES;

        this.init();
    }

    gridConfig() {
        return this.config.gridConfig;
    }

    init() {
        this.hide(); // hide the content by default
        if (this.config.hidden) {
            this.tab.hide(); // the tab won't be available as per the configuration.
        }
    }

    hide() {
        this.tab.removeClass('active');
        this.content.hide();
    }

    show() {
        this.tab.addClass('active');
        this.content.show();
    }
}

export default class UI {
    constructor(image_data, colorData, sphere) {
        this.image_data = image_data;
        this.colorData = colorData;
        this.sphere = sphere;
        this.controlsOpen = false;
        this.tabs = {
            [Const.COLOR_SPHERE]: new UiTab(Const.COLOR_SPHERE, '#ui_c_sphere', '.tab .ui_tab[alt="color_sphere"]', this.sphere),
            [Const.COLOR_DATA]: new UiTab(Const.COLOR_DATA, '#ui_c_data', '.tab .ui_tab[alt="color_data"]', this.colorData),
            [Const.IMAGE_DATA]: new UiTab(Const.IMAGE_DATA, '#ui_c_image', '.tab .ui_tab[alt="image_data"]', this.image_data),
            [Const.COORDINATES]: new UiTab(Const.COORDINATES, '#ui_c_coords', '.tab .ui_tab[alt="coordinates"]', grid),
        };

        this.activeTab = this.tabs[Debug.config.initial_tab];
        this.gridTab = this.tabs[Const.COORDINATES];

        this.gridControls = new DotControls($('#ui_c_coords'));
        window.gridControls = this.gridControls;

        this.addEvents();
        this.prepare();
    }

    prepare() {
        // $("#ui_c_data").hide();
        // $("#ui_c_coords").hide();
        // $("#ui_c_image").hide();
        // $("#ui_c_sphere").hide();
        //
        // Object.values(this.tabs).forEach(tab => {
        //     if (tab.config && tab.config.hidden) {
        //         tab.content.hide();
        //         tab.tab.hide();
        //     }
        // });

        this.activateTab(this.activeTab);
        this.showControls(this.controlsOpen);
    }

    activateTab(newTab) {
        if (this.activeTab) {
            // Hide currently active tab
            this.activeTab.hide();

            // Don't hide 3D content of active tab if the new tab is the Grid tab.
            // 3D content of grid tab must coexist with the other tabs' 3D content.
            if (!newTab.isGrid) {
                this.activeTab.setActive(false);
            }
        }
        //Hide UI content of grid tab if selected tab is a different one.
        if (!newTab.isGrid) {
            this.gridTab.hide();
            this.activeTab = newTab;
        }

        //Activate new tab
        newTab.show();
        newTab.setActive(true);

        if (!newTab.isGrid) {
            //Update UI controls in grid tab.
            this.setGridControls(this.activeTab.gridConfig());
            // Set grid properties using the active tabs preferences.
            //  Note that the gridConfig is passed by reference.
            //  So whenever the grid is updated by the user, the configuration of active tab is updated.
            //  This way each tab can have it's own grid configuration.
            grid.setActive(true, this.activeTab.gridConfig());
        }

        selectionManager.setSelectedDot(null);
        InfoPopup.closeAll();
    }

    setGridControls(config) {
        this.gridControls.setFromConfig(config);
    }

    addEvents() {
        var _this = this;

        $(".ui_tab").click(function () {
            console.log("ui_tab:: click");
            let alt = $(this).attr("alt");
            _this.activateTab(_this.tabs[alt]);
        });

        $("#ui_open_arrow > .icon").click(() => {
            console.log("ui_open_arrow");
            this.controlsOpen = !this.controlsOpen;

            this.showControls(this.controlsOpen);
            this.showTitle(!this.controlsOpen);

            this.wraperBgColor(false);
        });

        $("#control_right_side > .close_btn").click(() => {
            this.showControls(false);
            this.showTitle(true);

            $("#ui_open_arrow > .icon").removeClass('active');

            this.controlsOpen = false;

            this.wraperBgColor(true);
        });


        // $(".btn-group > .btn").click(function(){
        $(document).on('click', '.btn-group > .btn', function () {
            var parent = $(this).parent();

            parent.children().removeClass('active');
            $(this).addClass("active");

            var type = parent.attr("alt");
            var val = $(this).attr("alt");
            var tab = $(this).closest(".c_box")[0].id;

            var data = {tab: tab, type: type, val: val};

            switch (type) {
                case "distribution":
                    break;
                case "shape":
                    var lineEl = $(this).closest(".ui_line")[0];
                    // var groupId = Number( $(lineEl).attr("alt") );
                    var groupId = $(lineEl).attr("alt");
                    data.groupId = groupId;
                    break;
            }


            _this.change(data);
        });

        $(document).on('click', '.i_eye', function () {
            var mode = $(this).hasClass("active");
            $(this).toggleClass("active");

            let slider = $(this).parent().children('.ui_slider')[0];
            let val = (mode) ? 100 : 0;
            $(slider).val(val);
            $(slider).trigger('change');
            slider.setAttribute('value', val);

            _this.sliderChange(slider);
        });


        $('#ui').on('input', '.slider', function (event, ui) {
            _this.sliderChange(this);
        });

        $(document).on('change', '.form-switch', function (event, ui) {
            var tab = $(this).closest(".c_box")[0].id;
            var val = event.target.checked;
            var type = $(this).parent().attr("alt");

            var data = {tab: tab, type: type, val: val};

            if (tab == "ui_c_data") {
                var lineEl = $(this).closest(".ui_line")[0];
                // var groupId = Number( $(lineEl).attr("alt") );
                var groupId = $(lineEl).attr("alt");
                data.groupId = groupId;
            }

            _this.change(data);
        });


        $(".ui_btn").click(function () {
            console.log(this);
            var tab = $(this).closest(".c_box_fake")[0].id;
            var type = $(this).attr("alt");

            _this.change({tab: tab, type: type});
        });


    }

    sliderChange(el) {
        var type = $(el).parent().attr("alt");
        var val = Number(el.value);

        var data = {type: type, val: val};

        var c_box = $(el).closest(".c_box")[0];
        if (c_box != null) {
            var tab = $(el).closest(".c_box")[0].id;
            data.tab = tab;
        } else {
            data.tab = "ui_c_scene";
        }


        if (type == "opacity") {
            var eyeEl = $(el).parent().children('.i_eye')[0];
            var isActive = !$(eyeEl).hasClass("active");
            if (isActive && (val == 0)) {
                $(eyeEl).toggleClass("active") // deactivate
            } else if (!isActive && (val > 0)) {
                $(eyeEl).toggleClass("active") // activate
            }
        }

        var lineEl = $(el).closest(".ui_line")[0];
        if (lineEl) {
            // var groupId = Number( $(lineEl).attr("alt") );
            var groupId = $(lineEl).attr("alt");
            data.groupId = groupId;
        }


        this.change(data);
    }

    showControls(mode) {
        if (mode) {
            $("#ui_controls").show();
            $("#ui_tabs").show();
            setTimeout(function () {
                $("#ui_open_arrow").hide();
            }, 1000);
        } else {
            $("#ui_controls").hide();
            $("#ui_tabs").hide();
            $("#ui_open_arrow").show();
        }
    }

    showTitle(mode) {
        var el = $("#ui_title");
        if (mode) {
            el.show();
        } else {
            el.hide();
        }
    }

    wraperBgColor(mode) {
        var el = $("#ui_wrapper");
        var opac = (mode) ? 0.75 : 0.6;
        el.css("background-color", "rgba(0, 0, 0, " + opac + ")");
    }

    change(data) {
        let group;
        switch (data.tab) {

            case "ui_c_sphere":

                group = this.sphere.cloud;
                switch (data.type) {
                    case "opacity":
                        group.setOpacity(data.val);
                        group.setEnabled(data.val > 0);
                        break;

                    case "size":
                        group.setSize(data.val);
                        break;

                    case "density":
                        this.sphere.updateVal("density", data.val);
                        break;

                    case "3d":
                        group.set3D(data.val);
                        break;

                    case "distribution":
                        this.sphere.updateVal("distribution", data.val);
                        break;

                    case "reset":
                        resetDataSphere();
                        break;
                }

                break;

            case "ui_c_data":
                group = colorDots.groups[data.groupId];
                switch (data.type) {
                    case "opacity":
                        group.setEnabled(data.val > 0);
                        group.setOpacity(data.val);
                        break;

                    case "size":
                        group.setSize(data.val);
                        break;

                    case "3d":
                        group.set3D(data.val);
                        break;
                    case "upload":
                        if (login.loggedIn) {
                            this.colorData.file.loadFile();
                        } else {
                            login.show();
                        }
                        break;
                    case "download":
                        if (login.loggedIn) {
                            this.colorData.downloadFile();
                        } else {
                            login.show();
                        }
                        break;
                }

                break;

            case "ui_c_coords":
                switch (data.type) {
                    case "opacity":
                        grid.update('show', (data.val > 0));
                        grid.update('opacity', data.val);
                        break;
                    case "axis":
                        grid.update('show axis', data.val);
                        break;
                    case "circular":
                        grid.update('show circular', data.val);
                        break;
                    case "spherical":
                        grid.update('show spherical', data.val);
                        break;
                }
                break;

            case "ui_c_scene":

                switch (data.type) {
                    case "bg":
                        updateSceneBackground(data.val);
                        break;

                    case "reset":

                        resetDataSphere();

                        break;
                }

                break;

            case "ui_c_image":
                group = this.image_data.cloud;
                switch (data.type) {
                    case "upload":
                        if (login.loggedIn) {
                            this.image_data.file.loadFile();
                        } else {
                            login.show();
                        }
                        break;

                    case "wrap":
                        this.image_data.uniforms.wrap.value = data.val;
                        break;

                    case "opacity":
                        group.setEnabled(data.val > 0);
                        group.setOpacity(data.val);
                        break;

                    case "size":
                        group.setSize(data.val);
                        break;

                    case "density":
                        this.image_data.updateVal("density", data.val);
                        break;

                    case "3d":
                        group.set3D(data.val);
                        break;
                }

                break;

        }

    }

    static addCsvGroup(data) {

        switch (data.mode) {
            case "clean":
                $("#ui_c_data > .ui_lines").empty();
                break;
            case "add":
                let l = `<div class="ui_line" alt="${data.id}">
                        <div class="w100 inline-flex flexCenter">
                            <label class="form-label uppercase c_white"><span> ${data.name} </span></label>
                            <div class="ui_slider col-22 inline-flex" alt="opacity">
                                <div class="c_icon i_eye ${((data.enabled) ? '' : 'active')}"></div>
                                <input class="ui_slider slider tooltip" type="range" min="0" max="100" value="${data.opacity}" 
                                oninput="this.setAttribute('value', this.value);">
                            </div>
                            <div class="ui_slider col-22 inline-flex" alt="size">
                                <div class="c_icon i_size containCenter"></div>
                                <input class="ui_slider slider tooltip" type="range" min="0" max="100" value="${data.size}" 
                                oninput="this.setAttribute('value', this.value);">
                            </div>
                            <div class="ui_switch inline-flex flexCenter" alt="3d">
                                <div class="c_icon i_3d  ui_switch_text"></div>

                                <label class="form-switch">
                                    <input type="checkbox" checked>
                                    <i class="form-icon"></i> 
                                </label>
                            </div>
                            <div class="spacer col-14"></div>
                        </div>
                    </div>`;

                $("#ui_c_data > .ui_lines").append(l);
                break;
        }

    }
}
